export class Properties {
    constructor(
        public data1:string,
        public data2:string,
        public data3:string,
        public data4:string,
        public data5:string,
        public data6:string,
        public data7:string,
        public data8:string,
        public data9:string,
        public data10:string,
        public data11:string
    ){}
}
