import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ValidarTokenGuard implements CanActivate, CanLoad {

  constructor(
    private authService: AuthService,
    private router: Router
  ){}

  canActivate(): Observable<boolean> | boolean {
    return this.authService.validarToken()
      .pipe(
        tap(resp => {
          if (!resp) {
            this.notAuthorized();
          }
        })
      );
  }

  canLoad(): Observable<boolean> | boolean {
    return this.authService.validarToken()
      .pipe(
        tap(resp => {
          if (!resp) {
            this.notAuthorized();
          }
        })
      );
  }

  private notAuthorized() {
    const aux = this.authService.getUserLogin();
    if (aux) {
      const modo = aux.entrada;
      localStorage.clear();
      sessionStorage.clear();
      modo == 'Angular' ? this.router.navigate(['/login']) : window.location.href = "/login/";
    } else {
      localStorage.clear();
      sessionStorage.clear();
      window.location.href = "/login/";
    }
  }

}
