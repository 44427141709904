import { secret_key, secret_iv } from './../constant/constant';
import { UserLogin } from './../model/user-login.model';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UntypedFormGroup, ValidationErrors } from '@angular/forms';
import * as CryptoJS from 'crypto-js';
import { SnackBarComponent } from '../shared/components/snack-bar/snack-bar.component';


@Injectable({
  providedIn: "root",
})
export class UtilsService {
  public formatter = new Intl.NumberFormat("it-IT", {
    style: "currency",
    currency: "EUR",
  });

  public ComRegantes = 1;
  public plataformaAdjuntos = 1;

  constructor(
    private _snackBar: MatSnackBar,
  ) {}

  // Transforma una fecha Date a un string
  getFechaString(fecha: any) {
    let respuesta = "Sin Fecha";

    if (fecha !== null && fecha !== undefined) {
      const fechaDate = new Date(fecha);
      respuesta = `${fechaDate.getDate()}/${
        fechaDate.getMonth() + 1
      }/${fechaDate.getFullYear()}`;
    }

    return respuesta;
  }
/** Da el formato para guardar fechas en base de datos */
  getFechaBD(fecha) {
    const date = new Date(fecha);
    const dateStr =
      date.getFullYear() +
      "-" +
      ("00" + (date.getMonth() + 1)).slice(-2) +
      "-" +
      ("00" + date.getDate()).slice(-2) +
      " " +
      ("00" + date.getHours()).slice(-2) +
      ":" +
      ("00" + date.getMinutes()).slice(-2) +
      ":" +
      ("00" + date.getSeconds()).slice(-2);

    return dateStr;
  }

  /** Da el formato para guardar fechas en base de datos */
  getFechaFormatoBarra_OnlyDate(fecha) {
    const date = new Date(fecha);
    const dateStr =
      date.getFullYear() +
      "/" +
      ("00" + (date.getMonth() + 1)).slice(-2) +
      "/" +
      ("00" + date.getDate()).slice(-2);
    return dateStr;
  }


  /**
   * Metodo para parsear fecha de base de datos
   * @param date Fecha a parsear
   * @returns Retorna la fecha en formato YYYY-MM-DD
   */
  getFechaBD_OnlyDate(fecha: Date) {
    const date = new Date(fecha);
    const dateStr =
      date.getFullYear() +
      "-" +
      ("00" + (date.getMonth() + 1)).slice(-2) +
      "-" +
      ("00" + date.getDate()).slice(-2);
    return dateStr;
  }

  // Obtiene fecha y hora en formato string recuperada de base de datos
  getFechaHoraString(fecha) {

    if (fecha == ''){
      return 'Sin Fecha';
    }
    
    const date = new Date(fecha);
    const dateStr =
      date.getFullYear() +
      "/" +
      ("00" + (date.getMonth() + 1)).slice(-2) +
      "/" +
      ("00" + date.getDate()).slice(-2) +
      " " +
      ("00" + date.getHours()).slice(-2) +
      ":" +
      ("00" + date.getMinutes()).slice(-2) +
      ":" +
      ("00" + date.getSeconds()).slice(-2);

    return dateStr;
  }

  // le da formate de euros al precio recibido de base de datos
  formatCurrency(precio) {
    // Si es undefined o null retornar cero
    if (!precio) {
      return "0,00 €";
    }

    return this.formatter.format(precio); // "$1,000.00"
  }

  /**
   * FormatPrecioInput
   * @param precio precio en string
   * @param tipo_coste 0:default, 1:hora a mes, 2: mes a hora
   */
  formatPrecioInput(precio, tipo_coste: number = 0) {
    // Si es undefined o null retornar cero
    if (!precio) {
      return "0,00 €";
    }

    let original = precio;
    let aux = precio.toString();
    let test;

    //console.log('precio recibido en utils: ', precio);
    // console.log('precio tamaño: ',  precio.toString().length);

    if (
      precio.toString() === " €" ||
      precio.toString() === "€" ||
      precio.toString() === "" ||
      precio.toString() === undefined
    ) {
      console.log('entro a vacio');
      aux = "0.00";
    } else if (precio.toString().length <= 3) {
       console.log('entro a lenght <=3');
      if (precio.toString().indexOf(".")) {
        aux = precio + ".00";
      }
    } else if (
      precio.toString().indexOf("€") === -1 &&
      precio.toString().length > 3 &&
      typeof original !== "number"
    ) {
      //  console.log('entro a borrar');
        console.log('precio antes: ', aux);

      aux = aux.substring(0, aux.length - 2);

       console.log('precio luego: ', aux);
    } else if (precio.toString() === 1) {
       console.log('entro a decimal');
      precio = "0.0" + precio;
    } else if (precio.toString().length >= 3) {
      console.log('Precio:',precio.toString());
      //console.log('index .:',precio.toString().indexOf('.'));
      //console.log('length: ', precio.toString().length);
      if (precio.toString().indexOf(".") === precio.toString().length - 2) {
        console.log('entro');
        aux = precio + "0";
      } /*else if (precio.toString().indexOf(".") === -1) {
        //aux = precio + ".00";
      }*/
      console.log('---------------');
    }

    aux = aux.replace(/\./g, "");
    aux = aux.replace(/\,/g, "");
    aux = aux.replace(/\ /g, "");
    aux = aux.replace(/\s/g, "");
    aux = aux.replace(/\€/g, "");
    aux = aux.replace(/[^0-9]/g, "");

     console.log('aux limpio: ' + aux);

     console.log('aux primer corte: ' + aux.slice(0, aux.length - 2));
     console.log('aux primer segundo: ' + aux.slice(aux.length - 2, aux.length));
    test =
      aux.slice(0, aux.length - 2) +
      "." +
      aux.slice(aux.length - 2, aux.length);

    // console.log('length: ' + (aux.length - 2));
    // console.log( 'aux: ', test);

    // Coste hora a mensual
    if (tipo_coste === 1){
      let monto = Number.parseFloat(test);
      test = (monto * 8 * 22).toFixed(2);
    }
    else if(tipo_coste === 2){
      let monto = Number.parseFloat(test);
      test = ((monto / 22) / 8).toFixed(2);
    }
    return this.formatter.format(test); // "$1,000.00"
  }

  // combierte el formato del precio a uno compatible para ser usado en base de datos
  precioFormatoBD(precio) {
    let aux = precio;

    //console.log('precio recibido en utils: ', precio);

    aux = precio.toString().replace(/[^0-9]/g, "");
    aux =
      aux.slice(0, aux.length - 2) +
      "." +
      aux.slice(aux.length - 2, aux.length);

    return aux;
  }

  /**
   * Metodo que retorna las horas de una tarea dependiente de la fecha de inicio y fin
   * @param fecha_ini string
   * @param fecha_fin string
   */
  getHoursFromDates(fecha_ini: string, fecha_fin: string) {
    if (!fecha_ini || !fecha_fin) { return 0; }
    let date_1 = new Date(fecha_ini);
    let date_2 = new Date(fecha_fin);
    const day_as_milliseconds = 86400000;
    const diff_in_millisenconds = date_2.getTime() - date_1.getTime();
    const diff_in_days = diff_in_millisenconds / day_as_milliseconds + 1;
    const horas = diff_in_days * 8;
    return horas;
  }

  /**
   * Metodo para posicionar cualquier modal sobre un contenedor especifico
   * @param classNameModal Clase definida para el modal
   * @param classNameParent Clase el contenedor donde se desea ubicar
   * @param margenesLaterales Define margnenes left y right respecto al contenedor padre,
   * en porcentaje (Ejemplo 0.2 para un 20%)
   * @param margenesVerticales Define los margenes top y bottom respecto al contenedor padre,
   * en porcentaje (Ejemplo 0.2 para un 20%)
   * @param width // Define el ancho del modal en porcentaje con respecto al contenedor padre
   * @param height // Definr la altura del modal en porcentaje con respecto al contenedor padre
   *
   * Por ejemplo, si un contendor tiene 100w * 100h
   * Al definir los parametros margenesLaterales = 0.2, margenesVerticales = 0.2, width = 0.8 y height = 0.8
   * El modal tendrá el 80% del tamaño del contendor, y dejará un 20% de margenes (top,bottom,left,right)
   */
  onModalHtml(
    classNameModal: string,
    classNameParent: string,
    margenesLaterales: number,
    margenesVerticales: number,
    width: number,
    height: number
  ) {
    // Modal
    let modal = document.getElementsByClassName(
      classNameModal
    ) as HTMLCollectionOf<HTMLElement>;
    if (modal == undefined) {
      console.error("No fue posible encontrar el modal -> " + modal);
    }
    let wrapper = modal[0].getElementsByClassName(
      "modal-wrapper"
    ) as HTMLCollectionOf<HTMLElement>;
    // MAPA
    let contenedor = document.getElementsByClassName(
      classNameParent
    ) as HTMLCollectionOf<HTMLElement>;
    if (contenedor == undefined) {
      console.error("No fue posible encontrar el modal -> " + modal);
    }
    // Posicion absoluta del mapa
    //console.log(map[0].getClientRects()[0])
    let topContendor = contenedor[0].getClientRects()[0].top;
    let leftContendor = contenedor[0].getClientRects()[0].left;
    let widthContenedor = contenedor[0].offsetWidth;
    let heigthContenedor = contenedor[0].offsetHeight;
    //console.log(topMap, leftMap, widthMap, heigthMap);
    // Fijar posición horizontal
    wrapper[0].style.left =
      leftContendor + widthContenedor * margenesLaterales + "px";
    // Fijar Ancho
    wrapper[0].style.width = widthContenedor * width + "px";
    // Fijar posición vertical
    wrapper[0].style.top =
      topContendor + heigthContenedor * margenesVerticales + "px";
    // Fijar Altura
    wrapper[0].style.height = heigthContenedor * height + "px";
  }

  /**
   * Metodo para posicionar cualquier modal sobre un contenedor especifico
   * Esta configuración establece el modal con background en transparent
   * También elimina cualquier sombre natural del ion-modal
   * Esta pensado para permitida que el modal sea arrastable
   * @param classNameModal Class asignada al modal
   * @param classNameParent Class del contenedor donde se desea ubicar
   */
  onModalHtmlFullScreen(classNameModal: string, classNameParent: string) {
    // Modal
    const modal = document.getElementsByClassName(
      classNameModal
    ) as HTMLCollectionOf<HTMLElement>;
    const wrapper = modal[0].getElementsByClassName(
      "modal-wrapper"
    ) as HTMLCollectionOf<HTMLElement>;
    if (modal == undefined) {
      console.error("No fue posible encontrar el modal -> " + modal);
      return;
    } else {
      if (wrapper == undefined) {
        console.error("No fue posible encontrar el modal -> " + modal);
        return;
      } else {
        wrapper[0].style.background = "transparent";
        wrapper[0].style.boxShadow = "none";
        //wrapper[0].style.border = '1px solid black'; // Solo para testing
      }
    }
    // Contenedor donde se ubicará el modal drag
    const parent = document.getElementsByClassName(
      classNameParent
    ) as HTMLCollectionOf<HTMLElement>;
    if (parent == undefined) {
      console.error(
        "No fue posible encontrar el parent donde se ubicará el modal"
      );
      return;
    } else {
      // Obtener posicion absoluta del parent
      let topContendor = parent[0].getClientRects()[0].top;
      let leftContendor = parent[0].getClientRects()[0].left;
      let widthContenedor = parent[0].offsetWidth;
      let heigthContenedor = parent[0].offsetHeight;
      wrapper[0].style.position = "absolute";
      wrapper[0].style.left = leftContendor + "px";
      wrapper[0].style.top = topContendor + "px";
      wrapper[0].style.width = widthContenedor + "px";
      wrapper[0].style.height = heigthContenedor + "px";
    }
  }

  /**
   * Metodo para saber si una fecha esta dentro de un rango (Fecha de Incio y Fecha Fin)
   * Nota: Si la fecha incial y final son igual retornará 3, debido a que la fecha_objetivo(Actual) siempre será mayor
   * @param fecha_inicial Fecha incial del rango
   * @param fecha_final Fecha final del rango
   * @param fecha_objetivo Fecha a evaluar (Optional, si no se envía toma la actual)
   * @returns Si la fecha_objetivo está antes del rango retorna 1. Si esta dentro, retorna 2. Si esta después retorna 3.
   */
  compararFechas(
    fecha_inicial: Date,
    fecha_final: Date,
    fecha_objetivo?: Date
  ) {
    if (!fecha_objetivo) {
      //console.log("No hemos recibido fecha objetivo (fecha a evaluar) usaremos la actual");
    }
    fecha_objetivo = fecha_objetivo ? fecha_objetivo : new Date(new Date().toISOString().split('T')[0]);
    if(!isNaN(fecha_inicial.getTime()))
      fecha_inicial = new Date(fecha_inicial.toISOString().split('T')[0]);
    if(!isNaN(fecha_final.getTime()))  
      fecha_final = new Date(fecha_final.toISOString().split('T')[0]);
    
    //console.log("objetivo:", fecha_objetivo,fecha_objetivo.getTime());
    //console.log("Fecha inicial:",fecha_inicial ,fecha_inicial.getTime());
    //console.log("Fecha final:", fecha_final,fecha_final.getTime());

    // Verificar si la fecha objetivo esta dentro del rango recibido
    if (
      (fecha_objetivo.getTime() >= fecha_inicial.getTime() &&
      fecha_objetivo.getTime() <= fecha_final.getTime()) ||
      ( fecha_inicial.getTime() === fecha_final.getTime() && 
        fecha_final.getTime() ===  fecha_objetivo.getTime())
    ) {
      // Dentro del Rango
      //console.log('Dentro del rango', 1);
      return 1; // en proceso
    } else if (
      fecha_objetivo.getTime() <= fecha_inicial.getTime() &&
      fecha_objetivo.getTime() <= fecha_final.getTime()
    ) {
      // Antes del Rango
      //console.log('Antes del rango',0);
      return 0; //Pendiente
    } else if (
      fecha_objetivo.getTime() > fecha_inicial.getTime() &&
      fecha_objetivo.getTime() > fecha_final.getTime()
    ) {
      // Despues del Rango
      //console.log('Despues del rango', 2);
      return 2; //finalizado
    }
  }

  getConfHeader(): boolean {
    // Configuaración del Header
    let header = sessionStorage.getItem("header");
    if (!header) {
      // Si la configuración no existe se retornará true para mostrar el header
      return true;
    } else {
      // Si la configuración existe se retornará false para ocultar el header
      // También borraremos el registro
      //sessionStorage.removeItem('header');
      return false;
    }
  }

  // Elimina las comas de un float en formato String
  formatFloat(numero: string){
    //console.log("numero que entra: ", numero);
    numero = numero.replace(',', '.');
    //console.log("numero que sale: ", numero);

    return numero;
  }

  // Coloca coma en el float para ser visualizado
  showFloat(numero){
    let valor = numero.toString();
    valor = valor.replace('.', ',');
    return valor;
  }

  /**
   * Primer letra mayuscula
   */
  capitalizeFLetter(word: string) {
    var string = word;
    string = string[0].toUpperCase() + string.slice(1);
    return string;
  }

  /**
   * Primer letra mayuscula y elimina los _
   */
  formatCampo(word: string): string {
    var string = word;
    string = string[0].toUpperCase() + string.slice(1);
    let aux = string.split("_");
    // console.log(string);
    // console.log(aux)
    if (aux.length > 1) {
      string = aux[0] + " " + aux[1];
    } else {
      string = aux[0];
    }
    // console.log(string);
    return string;
  }

  getTypeErrorForm(typeError: string): string {
    switch (typeError) {
      case "required":
        return "Es requerido";
      default:
        return "Error en el campo";
    }
  }


   newDate(){
     let fecha = new Date();
     fecha.setHours(0,0,0,0);
    return fecha;
   }

  /**
   * Metodo sirve para determinar la validez de un formulario
   * Si existen errores retorna un array con el nombre del campo, tipo de error y el error
   * @param form Formulario a analizar
   * @returns ErrorForm[] | null
   * interface ErrorForm {
   *  campo:string;
   *  tipoError:string;
   *  valueError:boolean;
   * }
   */
  getFormValidationErrors(form: UntypedFormGroup): ErrorForm[] | null {
    let errors: ErrorForm[] = [];
    Object.keys(form.controls).forEach((key) => {
      const controlErrors: ValidationErrors = form.get(key).errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach((keyError) => {
          let error: ErrorForm = {
            campo: key,
            tipoError: keyError,
            valueError: controlErrors[keyError],
          };
          errors.push(error);
        });
      }
    });
    if (errors.length > 0) {
      return errors;
    } else {
      return null;
    }
  }
  
  /**
   * Metodo para desencriptar datos encriptados a traves del metodo AES-256-CBC
   * 
   * @param data_encrypted Data encriptada
   * @param secret_key Llave secreta utilizada para la encriptacion
   * @param secret_iv  LLave vectorial utilizada para la encriptacion
   * @returns Data desencriptada en string
   */
  decryptData(data_encrypted, secret_key, secret_iv): string {

    try {

      var Sha256 = CryptoJS.SHA256;
      var Hex = CryptoJS.enc.Hex;
      var Utf8 = CryptoJS.enc.Utf8;
  
      var key = Sha256(secret_key).toString(Hex).substr(0,32);
      var iv = Sha256(secret_iv).toString(Hex).substr(0,16);
  
      return CryptoJS.AES.decrypt(data_encrypted, Utf8.parse(key), {iv: Utf8.parse(iv),}).toString(Utf8);

     } catch (e) {
      throw new e;
    }
  }
  
  /**
   * Metodo encargado de devolver los datos del usuario autenticado por php almacenado en el session storage de manera ecryptada
   * Devuelve undefined en caso de que no exista en el session storage
   * 
   * @param user_login_encryted Data del usuario autenticado encriptada
   * @param secret_key Llave para la desencriptacion
   * @param secret_iv Llave vectorial para la desencriptacion
   * @returns Si existe los datos encriptados devuelve un objeto de UserLogin
   */
  decryptUserLogin(user_login_encryted, secret_key, secret_iv): UserLogin {

    var user_login: UserLogin = undefined;

    try {

      // Desencriptando data
      var data_decrypt = this.decryptData(user_login_encryted, secret_key, secret_iv);
      // Extrayendo y formateando la informacion del usuario
      var user_login_decrypt = data_decrypt.split(':');
                 
      user_login = {
          id:                    parseInt(user_login_decrypt[0]),
          num_socio:             parseInt(user_login_decrypt[1]),
          rol:                   parseInt(user_login_decrypt[2]),
          id_comunidad_regantes: parseInt(user_login_decrypt[3]),
          token:                 user_login_decrypt[4],
          entrada:               'PHP'
      }
      
    } catch (error) {
      user_login  = undefined;
    }
    
    // Devolviendo los datos del usuario autenticado por php
    return user_login;
  }
  
  /**
   * Metodo que devuelve los datos del usuario autenticado. Retorna undefined si no existen los datos en el sessionStorage
   */
  // getUserLogin(): UserLogin{
    
  //   var user_login_encryted =  sessionStorage.getItem("user_login");
  //   var user_login: UserLogin = this.decryptUserLogin(user_login_encryted, secret_key, secret_iv);
  //   return user_login;
  // }
  




  /**
  * Metodo que devuelve los datos del usuario autenticado. 
  * @returns 
  */
   getUserLogin(): UserLogin {
    const aux = sessionStorage.getItem("user_login");
    if (aux) {
      return JSON.parse(aux);
    } else {
      return undefined;
    }
  }
  
  /**
   * Metodo que establece los datos del usuario autenticado, tanto para php como para 
   * 
   * @param userLogin 
   */
/*   setUserLogin(userLogin: UserLogin){
    sessionStorage.setItem("user_login", JSON.stringify(userLogin));
  } */
  

  /**
   * Determina a partir de una string si es un número o no
   * @returns True = Si es un numero | False = Si no es un número
   */
  isStrNumber(x: string) {
    if (x === undefined || x === null) {
      return false;
    }
    let number: number = parseInt(x);
    if (isNaN(number)) {
      return false;
    } else {
      return true;
    }
  }

  /**
   * 
   * @param file Archivo a verificar
   * @param extensionesValidas Extensiones permitidas (Por defecto son ['png','jpg','jpeg'])
   * @returns Retorna true si la extesion del archivo es válida, False si no es válida
   */
  verificarExtension (file : File, extensionesValidas = ['png', 'jpg', 'jpeg']): boolean {
      // File a verificar
      const { name } = file;
      // Extraer extesion
      const array_name = name.split('.');
      const extension = array_name[array_name.length - 1];
      // Validar extensiones
      if (!extensionesValidas.includes(extension)) {
        return false;
      }
      return true;
  }

  /**
   * Metodo para obtener el primer y último día de un mes
   * @param date Fecha a analizar
   * @returns Retorna un objeto de tipo { primerDia:Date, ultimoDia:Date }
   */
  getPrimerDiaMes_UltimoDiaMes = (date: Date) => {
    const primerDia = new Date(date.getFullYear(), date.getMonth(), 1);
    const ultimoDia = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    return { primerDia, ultimoDia };
  }

  /**
   * Metodo para obtener el nombre del mes
   * @param date Fecha a analizar
   * @returns Retorna en string el nombre del mes en minisculas
   */
  getNombreMes = (date: Date) => {
    const nombreMeses = ["enero", "febrero", "marzo", "abril", "mayo", "junio",
      "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"
    ];
    return nombreMeses[date.getMonth()];
  }

  /**
   * Metodo para obtener lista para selects
   * @param enum ENUM a iterar
   * @returns lista con id: value
   */
  getListFromENUM(Enum: any) {
    console.log(Enum);
    return Object.keys(Enum)
      .map(key => ({ id: key, value: Enum[key] }));
  }
  
  /**
   * Metodo para obtener lista para selects
   * @param enum ENUM a iterar 
   * @returns lista con id: value solo ids numericos
   */
   getListFromIdENUM(Enum: any) {
    return Object.keys(Enum)
      .filter((value => isNaN(Number(value)) === false))
      .map(key => ({ id: key, value: Enum[key] }));
  }

  /**
   * Metodo para obtener lista para selects
   * @param start Año a iniciar la iteracion ej: 2022 
   * @param stop Año a finalizar la iteracion ej: 1990 
   * @param step cantidad de años a saltarse entre años ej: -1 es el defecto 
   * @returns lista de años 
   */
  getRangeYears(start, stop, step = -1){
    return Array.from({ length: (stop - start) / step + 1}, (_, i) => start + (i * step));
  } 

  /**
   * Metodo para ordenar propiedades de un objeto alfabeticamente
   * @param obj Objeto a ordenar
   * @returns Objeto ordenado 
   */
  sortObject(obj) {
    return Object.keys(obj)
      .sort().reduce((a, v) => {
      a[v] = isNaN(obj[v]) ? obj[v] : Number(obj[v]);
      return a; }, {});
  }
  //convertir string en número
  ConvertStringToNumber(input: string) {
    var numeric = Number(input);
    return numeric;
  }
  /**
   * Metodo para mostrar un mensaje con snackbar de duración finita
   * @param duration Duración del mensaje (En ms) Por defecto son 4000ms o 4s
   * @param message String con el mensaje a mostrar
   * @param style Define el estilo por ahora solo aplica para 'hidraulico' , 'mambiente','advertencia' o 'notificacion'  Por defecto es 'notificacion'
   */
  
   showSnackBar(message: string, duration: number = 4000, style: string = 'notificacion') {
    this._snackBar.openFromComponent(SnackBarComponent, {
      duration,
      data: { message },
      panelClass: `snack-bar-${style}`
    });
  }
}


export interface ErrorForm {
  campo:string;
  tipoError:string;
  valueError:boolean;
}
