import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { THEMES } from '../class/theme.config';
import { IThemeProperties } from '../model/theme.interface';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  constructor(@Inject(DOCUMENT) private document: Document) {}

  getTheme(): string {
    return sessionStorage.getItem('themeSelect') || 'default';
  }
 
  setTheme(name: string): void {
    sessionStorage.setItem('themeSelect', name);
    const theme: IThemeProperties = (THEMES as any)[name];
    Object.keys(theme).forEach((key: string) => {
      this.document.documentElement.style.setProperty(
        `--${key}`,
        (theme as any)[key]
      );
    });
  }

  setThemeByRoute(route: string): void {
    const routesWTheme = [
        {
            route: "/hidraulica/main",
            theme: "hidraulica"
        },
        {
            route: "/medio-ambiente/main",
            theme: "medioAmbiente"
        }
    ]
    const themeItem = routesWTheme.find(e => e.route == route);
    if (themeItem?.theme) {
        this.setTheme(themeItem?.theme);
    }
  }
}