/** Modelos para el Módulo de Gestión */
/**1.- Recursos */
export interface ResponseRecursos {
    status:   string;
    recursos: Recurso[];
}
export class Recurso {

    static generateRecurso(obj: Object) {
        return new Recurso(
            obj['id'],
            obj['id_socio'],
            obj['numero'],
            obj['categoria'],
            obj['id_elemento'],
            obj['identificador'],
            obj['informacion'],
            obj['observaciones'],
            obj['coste'],
        )
    }

    constructor(
        public id:            number,
        public id_socio:      number,
        public numero:        number,
        public categoria:     string,
        public id_elemento:   number,
        public identificador: string,
        public informacion:   string,
        public observaciones: string,
        public coste:         number,
    ) { }
}

/**2.- Equipos */
export interface ResponseEquipos {
    status:   string;
    equipos: Equipo[];
}
export class Equipo {

    static generateEquipo(obj: Object) {
        return new Equipo(
            obj['id'],
            obj['id_socio'],
            obj['numero'],
            obj['categoria'],
            obj['nombre'],
            obj['tipo'],
            obj['combustible'],
            obj['potencia'],
            obj['traccion'],
            obj['num_serie'],
            obj['matricula'],
            obj['foto'],
            obj['marca'],
            obj['modelo'],
            obj['roma'],
            obj['fecha_adq'],
            obj['fecha_insp'],
            obj['regimen'],
            obj['coste'],
            obj['coste_hora'],
            obj['modo_alquiler'],
            obj['amortizacion'],
            obj['year'],
        )
    }


    static generateCaracteristicasCostes(obj: Object, obj1: Object) {
        return new Equipo(
            obj['id'],
            obj['id_socio'],
            obj['numero'],
            obj['categoria'],
            obj['nombre'],
            obj['tipo'],
            obj['combustible'],
            obj['potencia'],
            obj['traccion'],
            obj['num_serie'],
            obj['matricula'],
            obj['foto'],
            obj['marca'],
            obj['modelo'],
            obj['roma'],
            obj['fecha_adq'],
            obj['fecha_insp'],
            obj1['regimen'],
            obj1['coste'],
            obj1['coste_hora'],
            obj1['modo_alquiler'],
            obj1['amortizacion'],
            obj1['year'],
        )
    }

    constructor(
        public id:          number,
        public id_socio:    number,
        public numero:      number,
        public categoria:   string,
        public nombre:      string,
        public tipo:        string,
        public combustible: string,
        public potencia:    number,
        public traccion:    string,
        public num_serie:     string,
        public matricula:   string,
        public foto:        string,
        public regimen:     string,
        public coste:       number,
        public coste_hora:  number,
        public marca:       string,
        public modelo:      string,
        public roma:        string,
        public fecha_adq:   string,
        public fecha_insp:  string,
        public modo_alquiler:   number,
        public amortizacion:   string,
        public year:        number,
    ) { }
}

/**3.- Personal */
export interface ResponsePersonal {
    status:   string;
    personal: Personal[];
}

export class Personal {

    static generateEquipo(obj: Object) {
        return new Personal(
            obj['id'],
            obj['id_socio'],
            obj['numero'],
            obj['categoria'],
            obj['nombre'],
            obj['apellidos'],
            obj['DNI'],
            obj['direccion'],
            obj['localidad'],
            obj['telefono'],
            obj['email'],
            obj['ropo'],
            obj['tipo_carnet'],
            obj['foto'],
            obj['regimen'],
            obj['coste'],
            obj['year'],
            obj['poblacion'],
            obj['ciudad'],
            obj['pais'],
        )
    }


    static generateCaracteristicasCostes(obj: Object, obj1: Object) {
        return new Personal(
            obj['id'],
            obj['id_socio'],
            obj['numero'],
            obj['categoria'],
            obj['nombre'],
            obj['apellidos'],
            obj['DNI'],
            obj['direccion'],
            obj['localidad'],
            obj['telefono'],
            obj['email'],
            obj['ropo'],
            obj['tipo_carnet'],
            obj['foto'],
            obj1['regimen'],
            obj1['coste'],
            obj1['year'],
            obj['poblacion'],
            obj['ciudad'],
            obj['pais'],
        )
    }

    constructor(
        public id:          number,
        public id_socio:    number,
        public numero:      number,
        public categoria:   string,
        public nombre:      string,
        public apellidos:   string,
        public DNI:         string,
        public direccion:    string,
        public localidad:   number,
        public telefono:    number,
        public email:       string,
        public ropo:        string,
        public tipo_carnet: number,
        public foto:        string,
        public regimen:     string,
        public coste:       number,
        public year:        number,
        public poblacion:     number,
        public ciudad:       number,
        public pais:        number,
    ) { }
}

/**3.- Fertilizantes */
export interface ResponseFertilizantes {
    status:        string;
    fertilizantes: Fertilizante[];
}

export class Fertilizante {

    static generateFertilizante(obj: Object) {
        return new Fertilizante(
            obj['id'],
            obj['id_socio'],
            obj['numero'],
            obj['categoria'],
            obj['nombre'],
            obj['tipo'],
            obj['descripcion'],
            obj['num_registro'],
            obj['cont_n'],
            obj['cont_p'],
            obj['cont_k'],
            obj['cont_s'],
            obj['cont_ca'],
            obj['cont_mg'],
            obj['cont_bo'],
            obj['cont_z'],
            obj['cont_cu'],
            obj['cont_fe'],
            obj['cont_mn'],
            obj['cont_mo'],
            obj['coste'],
            obj['cantidad'],
            obj['caducidad'],
        )
    }


    static generateCaracteristicasCostes(obj: Object, obj1: Object, obj2: Object) {
        return new Fertilizante(
            obj['id'],
            obj['id_socio'],
            obj['numero'],
            obj['categoria'],
            obj['nombre'],
            obj['tipo'],
            obj['descripcion'],
            obj['num_registro'],
            obj2['cont_n'],
            obj2['cont_p'],
            obj2['cont_k'],
            obj2['cont_s'],
            obj2['cont_ca'],
            obj2['cont_mg'],
            obj2['cont_bo'],
            obj2['cont_z'],
            obj2['cont_cu'],
            obj2['cont_fe'],
            obj2['cont_mn'],
            obj2['cont_mo'],
            obj1['coste'],
            obj1['cantidad'],
            obj1['caducidad'],
        )
    }

    constructor(
        public id:           number,
        public id_socio:     number,
        public numero:       number,
        public categoria:    string,
        public nombre:       string,
        public tipo:         string,
        public descripcion:  string,
        public num_registro: string,
        public cont_n:       number,
        public cont_p:       number,
        public cont_k:       number,
        public cont_s:       number,
        public cont_ca:      number,
        public cont_mg:      number,
        public cont_bo:      number,
        public cont_z:       number,
        public cont_cu:      number,
        public cont_fe:      number,
        public cont_mn:      number,
        public cont_mo:      number,
        public coste:        number,
        public cantidad:     number,
        public caducidad:    Date,
    ) { }
}

/**4.- Fitosanitarios */
export interface ResponseFitosanitarios {
    status:         string;
    fitosanitarios: Fitosanitario[];
}

export class Fitosanitario {

    static generateFertilizante(obj: Object) {
        return new Fitosanitario(
            obj['id'],
            obj['id_socio'],
            obj['numero'],
            obj['categoria'],
            obj['nombre'],
            obj['tipo'],
            obj['descripcion'],
            obj['num_registro'],
            obj['m_activa'],
            obj['riqueza'],
            obj['descripcion2'],
            obj['inscripcion'],
            obj['caducidad'],
            obj['coste'],
            obj['cantidad'],
            obj['fecha_compra']
        )
    }

    static generateCaracteristicasCostes(obj: Object, obj1: Object, obj2: Object) {
        return new Fitosanitario(
            obj['id'],
            obj['id_socio'],
            obj['numero'],
            obj['categoria'],
            obj['nombre'],
            obj['tipo'],
            obj['descripcion'],
            obj['num_registro'],
            obj2['m_activa'],
            obj2['riqueza'],
            obj2['descripcion2'],
            obj2['inscripcion'],
            obj2['caducidad'],
            obj1['coste'],
            obj1['cantidad'],
            obj1['fecha_compra']
        )
    }

    constructor(
        public id:           number,
        public id_socio:     number,
        public numero:       number,
        public categoria:    string,
        public nombre:       string,
        public tipo:         string,
        public descripcion:  string,
        public num_registro: string,
        public m_activa:     string,
        public riqueza:      number,
        public descripcion2: string,
        public inscripcion:  Date,
        public caducidad:    Date,
        public coste:        number,
        public cantidad:     number,
        public fecha_compra: Date,
    ) { }
}

/**5.- Servicios */
export interface ResponseServicios {
    status:    string;
    servicios: Servicio[];
}
export class Servicio {

    static generateServicio(obj: Object) {
        return new Servicio(
            obj['id'],
            obj['id_socio'],
            obj['numero'],
            obj['categoria'],
            obj['nombre'],
            obj['tipo'],
            obj['cif'],
            obj['direccion'],
            obj['localidad'],
            obj['nombre_contacto'],
            obj['apellidos_contacto'],
            obj['dni'],
            obj['telefono'],
            obj['email'],
            obj['servicio_contratado'],
            obj['regimen'],
            obj['coste_mensual'],
            obj['fecha']
        )
    }

    static generateCaracteristicasCostes(obj: Object, obj1: Object) {
        return new Servicio(
            obj['id'],
            obj['id_socio'],
            obj['numero'],
            obj['categoria'],
            obj['nombre'],
            obj['tipo'],
            obj['cif'],
            obj['direccion'],
            obj['localidad'],
            obj['nombre_contacto'],
            obj['apellidos_contacto'],
            obj['dni'],
            obj['telefono'],
            obj['email'],
            obj1['servicio_contratado'],
            obj1['regimen'],
            obj1['coste_mensual'],
            obj1['fecha']
        )
    }

    constructor(
        public id:                  number,
        public id_socio:            number,
        public numero:              number,
        public categoria:           string,
        public nombre:              string,
        public tipo:                string,
        public cif:                 string,
        public direccion:           string,
        public localidad:           number,
        public nombre_contacto:     string,
        public apellidos_contacto:  string,
        public dni:                 string,
        public telefono:            number,
        public email:               string,
        public servicio_contratado: string,
        public regimen:             string,
        public coste_mensual:       number,
        public fecha:               Date,
    )  { }
}