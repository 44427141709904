import { ResponseRecursoAguaDeRiego } from './../../model/recursos.model';
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";
import { ResponseVentas } from "src/app/model/produccion.model";
import {
  Noticias,
  MenuOpcion,
  MenuOpcionLateral,
  Socio,
  Provincia,
  Localidad,
  ResponseProvincias,
  ResponseLocalidades,
  MenuMap,
  ResponseTurnosRiego,
  ResponseRecursos,
  Recurso,
  ResponseEquipos,
  Equipo,
  ResponsePersonal,
  Personal,
  ResponseFertilizantes,
  ResponseFitosanitarios,
  ResponseServicios,
  Fertilizante,
  Fitosanitario,
  Servicio,
  ResponseParcelas,
  ResponsePoligonos,
  Tarea,
  ResponseMunicipios  
} from "src/app/model";
import { environment } from "src/environments/environment";

// Capas NDVI
// Library Geotaster - GeoTIFF
import * as L from "leaflet";
import "proj4leaflet";
import { ResponseTurnosRiegoOpen } from "src/app/model/turnos-de-riego-open.model";
import {
  Articulo,
  Categoria,
  ResponseCategorias,
  ResponsePedidosTotalEstado,
  ResponseProveedor,
  ResponseStock,
  ResponseTipoElementos,
} from "src/app/model/stock.model";
import {
  DetallesTarea,
  DetallesTareaCreate,
  ResponseDetallesTareas,
  ResponseTareas,
  TareaCreate,
} from "src/app/model/tarea.model";
import {
  ResponseRecursoEquipos,
  ResponseRecursoFertilizantes,
  ResponseRecursoFitos,
  ResponseRecursoLocales,
  ResponseRecursoPersonal,
  ResponseRecursoServicios,
  ResponseRecursoVehiculos,
  ResponseRecursoSemillas
} from "src/app/model/recursos.model";
import { ResponseProblemasFito } from "src/app/pages/cuaderno-de-campo/tareas/model/problemas-fito.model";
import { ResponseTareasFitoTarea } from "src/app/pages/cuaderno-de-campo/tareas/model/tareas-recurso-tarea.model";
import {
  ResponseLocaliades,
  ResponseProvinciasSigpac,
} from "src/app/model/provincias.model";
import { UtilsService } from "../utils.service";
const parse_georaster = require("georaster");
const GeoRasterLayer = require("georaster-layer-for-leaflet");

@Injectable({
  providedIn: "root",
})
/** Servicio encargado de Recuperar la data JSON
 *  Este servicio simula la base de datos
 */
export class DataService {
  /** Data (Será consultada a la API mediante un SELECT) */
  //dataTarea: Tarea[];

  /** Data (Será consultada a la API mediante un SELECT) */
  /* dataTareaDetalle: TareaDetalle[] = [
      { 
        id: 1,
        num_orden: 1,
        tarea: 'Podar Parcela 135',
        recurso: 'Manolo',
        fecha_incio: '10/10/2020',
        fecha_fin: '15/10/2020',
        coste: 20,
        horas: 5
       },
       {
        id: 2,
        num_orden: 1,
        tarea: 'Podar Parcela 135',
        recurso: 'Juan',
        fecha_incio: '10/10/2020',
        fecha_fin: '15/10/2020',
        coste: 30,
        horas: 6
       },
       { 
        id: 3,
        num_orden: 2,
        tarea: 'Abonar Parcela 136',
        recurso: 'Tractor',
        fecha_incio: '15/10/2020',
        fecha_fin: '25/10/2020',
        coste: 100,
        horas: 5
       },
       { 
        id: 4,
        num_orden: 2,
        tarea: 'Abonar Parcela 136',
        recurso: 'Fitosanitario1',
        fecha_incio: '15/10/2020',
        fecha_fin: '25/10/2020',
        coste: 30,
        horas: 5
       },
       { 
        id: 5,
        num_orden: 2,
        tarea: 'Abonar Parcela 136',
        recurso: 'Fertilzante1',
        fecha_incio: '15/10/2020',
        fecha_fin: '25/10/2020',
        coste: 100,
        horas: 0
       },
       { 
        id: 6,
        num_orden: 2,
        tarea: 'Abonar Parcela 136',
        recurso: 'Proversa',
        fecha_incio: '15/10/2020',
        fecha_fin: '25/10/2020',
        coste: 100,
        horas: 0
       },
    ];*/

  /** Controlador de Capas */
  public menuMapOpts: MenuMap[];
  /** Controlador de Mapa y Paneles de Informacion */
  public menuMapController: MenuMap[];

  public url: string;

  /** Provincias */
  public provincias: Provincia[] = [];
  /** Provincias */
  public localidades: Localidad[] = [];

  /** Socio */
  public dni: string = "20154383-N";
  public static socio: Socio;

  /** Controlador de Layers MAPA */
  private $menuMapSubject = new Subject<MenuMap[]>();
  public $menuMap = this.$menuMapSubject.asObservable();

  /** MENU LATERAL IZQUIERDO - MENU PRINCIPAL */
  private $menuMapControllerSubject = new Subject<MenuMap[]>();
  public $menuMapController = this.$menuMapControllerSubject.asObservable();

  /** Modulo de Gestión - Recursos */
  public recursos: Recurso[];
  private $recursosSubject = new Subject<Recurso[]>();
  public $recursos = this.$recursosSubject.asObservable();

  /** Modulo de Gestión - Tareas */
  private $tareasSubject = new Subject<Tarea[]>();
  public $tareas = this.$tareasSubject.asObservable();

  /** Modulo de Gestión - TareasDetalles */
  private $tareasDetallesSubject = new Subject<DetallesTarea[]>();
  public $tareasDetalles = this.$tareasDetallesSubject.asObservable();

  constructor(private _http: HttpClient, private us: UtilsService) {
    this.url = environment.url;
    this.getMenuMapOpts().subscribe();
  }

  /** Noticias */
  getNews(orden: string, noticiaTipo: number): Observable<Noticias[]> {
    if (orden.length == 0) {
      return this._http.get<Noticias[]>("/assets/data/noticias.json");
    }
    if (orden == "criterio") {
      return this._http.get<Noticias[]>(
        `/assets/data/noticias_criterio${noticiaTipo}.json`
      );
    }
  }

  /** Noticias por Fecha */
  getNewsByDate(orden: boolean) {
    return this._http.get<Noticias[]>("/assets/data/noticias.json").pipe(
      map((resp) => {
        var aux = resp.map((noticia) => Noticias.generateNoticias(noticia));
        if (orden == false) {
          return aux.sort((a, b) => a.fecha.getTime() - b.fecha.getTime());
        } else {
          return aux.sort((a, b) => a.fecha.getTime() + b.fecha.getTime());
        }
      })
    );
  }

  /** Home Modulos */
  getMenuOpts(idUsuario) {
    //return this._http.get<MenuOpcion[]>("/assets/data/menu.json").pipe(
    return this._http.get<MenuOpcion[]>("/assets/data/menu_only_cuaderno.json").pipe(/*solo cuaderno*/ 
      map((resp) => {
        /*if (idUsuario == 387) {
          //resp = resp.filter((opcion) => opcion.name == "cuadernoC"); 
          resp = resp.filter((opcion) => opcion.name.includes("cuadernoC")); 
          return resp.map((opcion) => MenuOpcion.generateOpcion(opcion)); 
        }  else*/
          return resp.map((opcion) => MenuOpcion.generateOpcion(opcion));
      })
    );
  }

  /** Home Modulos */
  getMenuLateralOpts() {
    return this._http
      //.get<MenuOpcionLateral[]>("/assets/data/menu_lateral.json")
      .get<MenuOpcionLateral[]>("/assets/data/menu_lateral_only_cuaderno.json")
      .pipe(
        map((resp) => {
          return resp.map((opcion) => MenuOpcionLateral.generateOpcion(opcion));
        })
      );
  }

  /** Menu MAPA - ESTA LLAMADA INCICIALIZA EL METODO DE NUEVO - SOLO DEBE LLAMARSE UNA VEZ */
  getMenuMapOpts() {
    return this._http.get<MenuMap[]>("/assets/data/menu_map.json").pipe(
      map((resp) => {
        resp = resp.map((opcion) => MenuMap.generateOpcion(opcion));
        this.enviarMenuMap(resp);
        return resp;
      })
    );
  }

  /** Observable MenuMap - Controlador de Capas */
  enviarMenuMap(data: MenuMap[]) {
    this.menuMapOpts = data;
    this.$menuMapSubject.next(this.menuMapOpts);
  }
  /** Metodo para realizar cambios sobre el Menu de Opts Segundo Nivel */
  changeMenuMap(
    opcionName: string,
    childrenName: string,
    childrenProperties: string,
    value: any
  ) {
    /*if (!this.menuMapOpts) {
      return;
    }*/
    this.menuMapOpts = this.menuMapOpts.map((opt) => {
      if (opt.name == opcionName) {
        opt.children = opt.children.map((baselayer) => {
          if (baselayer.name == childrenName) {
            baselayer[childrenProperties] = value;
          }
          return baselayer;
        });
      }
      return opt;
    });
    this.enviarMenuMap(this.menuMapOpts);
  }

  /** Metodo para realizar cambios sobre el Menu de Opts Tercer Nivel */
  changeMenuMap_2(
    opcionName: string,
    childrenName: string,
    childrenName_2: string,
    childrenProperties: string,
    value: any
  ) {
    if (!this.menuMapOpts) {
      return;
    }
    this.menuMapOpts = this.menuMapOpts.map((opt) => {
      if (opt.name == opcionName) {
        opt.children = opt.children.map((child) => {
          if (child.children != undefined && child.name == childrenName) {
            child.children = child.children.map((child_2) => {
              if (child_2.name == childrenName_2) {
                child_2[childrenProperties] = value;
              }
              return child_2;
            });
          }
          return child;
        });
      }
      return opt;
    });
    this.enviarMenuMap(this.menuMapOpts);
  }

  /** Menu Principal Izquierda - Controlador de Filtro, Configuraciones, Paneles de Información */
  getMenuMapController() {
    return this._http
      .get<MenuMap[]>("/assets/data/menu_map_controller.json")
      .pipe(
        map((resp) => {
          resp = resp.map((opcion) => MenuMap.generateOpcion(opcion));
          this.enviarMenuMapController(resp);
          return resp;
        })
      );
  }

  /** Observable MenuMapController - Controlador de Filtro, Configuraciones, Paneles de Información */
  enviarMenuMapController(data: MenuMap[]) {
    this.menuMapController = data;
    this.$menuMapControllerSubject.next(this.menuMapController);
  }

  /** Metodo para realizar cambios sobre el Menu de Configuración en el Segundo Nivel */
  changeMenuMapController(
    opcionName: string,
    childrenName: string,
    childrenProperties: string,
    value: any
  ) {
    this.menuMapController = this.menuMapController.map((opt) => {
      if (opt.name == opcionName) {
        opt.children = opt.children.map((baselayer) => {
          if (baselayer.name == childrenName) {
            baselayer[childrenProperties] = value;
          }
          return baselayer;
        });
      }
      return opt;
    });
    this.enviarMenuMapController(this.menuMapController);
  }

  /**
   * Data GLOBAL
   */
  /** Poligonos -
   *  Devuelve una lista de poligonos * parcelas
   * Por ID del Socio */
  getPoligonos(idSocio: number) {
    let params = JSON.stringify({ idSocio });
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    return this._http.post<ResponsePoligonos>(
      this.url + "get-poligonos/",
      params,
      { headers: headers }
    );
  }

  /** Parcelas - Por NUMERO DEL SOCIO */
  getParcelas(idSocio: number) {
    let params = JSON.stringify({ idSocio });
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    return this._http.post<ResponseParcelas>(
      this.url + "get-parcelas/",
      params,
      { headers: headers }
    );
  }

  /** Provincias - SERÁ ELIMINADA (DEPRECATED) */
  getProvincias() {
    return this._http
      .get<ResponseProvincias>(this.url + "get-provincias/")
      .pipe(
        map((resp) => {
          this.provincias = resp.provincias;
          return this.provincias;
        })
      );
  }

  /** Localidades - SERÁ ELIMINADA (DEPRECATED) */
  getLocalidades() {
    return this._http
      .get<ResponseLocalidades>(this.url + "get-localidades/")
      .pipe(
        map((resp) => {
          this.localidades = resp.localidades;
          return this.localidades;
        })
      );
  }

  /** Localidades */
  getMunicipios(idSocio: number) {
    let params = JSON.stringify({ idSocio });
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    return this._http
      .post<ResponseMunicipios>(this.url + "get-municipios/", params, {
        headers: headers,
      })
      .pipe(
        map((resp) => {
          //this.localidades = resp.municipios;
          return resp.municipios;
        })
      );
  }

  /** Turnos de Riego - Get Hidrantes en modo gravedad o propulsion */
  /* getTurnosRiego(modo: string) {
    return this._http.get<ResponseTurnosRiego>(this.url + 'get-turnos-riego/' + modo).pipe(map(response => {
     console.log('turnos de riego: ', response);
      return response;}));
  }*/

  getTurnosRiego(modo: string) {
    return this._http
      .get<ResponseTurnosRiego>(this.url + `get-turnos-riego/${modo}`)
      .pipe(
        map((response) => {
          //console.log('turnos de riego: ', response);
          return response;
        })
      );
  }

  /** Turnos de Riego - Get Hidrantes abiertos */
  getOpenHidrantes() {
    return this._http.get<ResponseTurnosRiegoOpen>(
      this.url + "get-open-hidrante/"
    );
  }

  /**
   * GESTION DATA
   * Peticiones para el Módulo Gestión
   */

  /**
   * Metodo para obtener los recursos de un socio
   * @param num_socio Número de Socio
   */
  getRecursos(num_socio: number) {
    return this._http
      .get<ResponseRecursos>(this.url + "get-recursos/" + num_socio)
      .pipe(
        map((response) => {
          this.recursos = response.recursos;
          return response.recursos;
        })
      );
  }
  /** Observable MenuMap - Controlador de Capas */
  enviarRecursos(data: Recurso[]) {
    this.$recursosSubject.next(data);
  }

  /**
   * Metodo para obtener los equipos de un socio
   * @param num_socio Número de Socio
   */
  getEquipos(num_socio: number) {
    return this._http.get<ResponseEquipos>(
      this.url + "recursos/get-equipos/" + num_socio
    );
  }

  /**
   * Metodo para crear un nuevo equipo
   * @param equipo Equipo a crear
   */
  createEquipo(equipo: Equipo) {
    let params = JSON.stringify(equipo);
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    return this._http.post<{ status: string; recursoID: number }>(
      this.url + "recursos/save-equipo",
      params,
      { headers: headers }
    );
  }

  /**
   * Metodo para Actualizar equipo
   * @param equipo Equipo a Actualizar
   * @param recurso Recurso a Actualizar
   */
  updateEquipo(equipo: Equipo, recurso: Recurso) {
    var aux = {
      equipo: equipo,
      recurso: recurso,
    };
    let params = JSON.stringify(aux);
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    return this._http.put<{ status: string; recursoID: number }>(
      this.url + "update-equipo/",
      params,
      { headers: headers }
    );
  }

  /**
   * Metodo para obtener  los equipos de un socio
   * @param num_socio Número de Socio
   */
  getPersonal(num_socio: number) {
    return this._http.get<ResponsePersonal>(
      this.url + "get-personal/" + num_socio
    );
  }

  /**
   * Metodo para crear un nuevo personal
   * @param personal Equipo a crear
   */
  createPersonal(personal: Personal) {
    let params = JSON.stringify(personal);
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    return this._http.post<{ status: string; recursoID: number }>(
      this.url + "save-personal/",
      params,
      { headers: headers }
    );
  }

  /**
   * Metodo para Actualizar personal
   * @param personal Personal a Actualizar
   * @param recurso Recurso a Actualizar
   */
  updatePersonal(personal: Personal, recurso: Recurso) {
    var aux = {
      personal: personal,
      recurso: recurso,
    };
    let params = JSON.stringify(aux);
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    return this._http.put<{ status: string; recursoID: number }>(
      this.url + "update-personal/",
      params,
      { headers: headers }
    );
  }

  /**
   * Metodo para obtener los fertilizantes de un socio
   * @param num_socio Número de Socio
   */
  getFertilizantes(num_socio: number) {
    return this._http.get<ResponseFertilizantes>(
      this.url + "get-fertilizantes/" + num_socio
    );
  }

  /**
   * Metodo para crear un nuevo fertilizante
   * @param fertilizante Equipo a crear
   */
  createFertilizante(fertilizante: Fertilizante) {
    let params = JSON.stringify(fertilizante);
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    return this._http.post<{ status: string; recursoID: number }>(
      this.url + "save-fertilizante/",
      params,
      { headers: headers }
    );
  }

  /**
   * Metodo para Actualizar Fertilizante
   * @param fertilizante Fertilizante a Actualizar
   * @param recurso Recurso a Actualizar
   */
  updateFertilizante(fertilizante: Fertilizante, recurso: Recurso) {
    var aux = {
      fertilizante: fertilizante,
      recurso: recurso,
    };
    let params = JSON.stringify(aux);
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    return this._http.put<{ status: string; recursoID: number }>(
      this.url + "update-fertilizante/",
      params,
      { headers: headers }
    );
  }

  /**
   * Metodo para obtener los fitosanitarios de un socio
   * @param num_socio Número de Socio
   */
  getFitosanitarios(num_socio: number) {
    return this._http.get<ResponseFitosanitarios>(
      this.url + "get-fitosanitarios/" + num_socio
    );
  }

  /**
   * Metodo para crear un nuevo fitosanitario
   * @param fitosanitario Equipo a crear
   */
  createFitosanitario(fitosanitario: Fitosanitario) {
    let params = JSON.stringify(fitosanitario);
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    return this._http.post<{ status: string; recursoID: number }>(
      this.url + "save-fitosanitario/",
      params,
      { headers: headers }
    );
  }

  /**
   * Metodo para Actualizar Fitosanitario
   * @param fitosanitario Fitosanitario a Actualizar
   * @param recurso Recurso a Actualizar
   */
  updateFitosanitario(fitosanitario: Fitosanitario, recurso: Recurso) {
    var aux = {
      fitosanitario: fitosanitario,
      recurso: recurso,
    };
    let params = JSON.stringify(aux);
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    return this._http.put<{ status: string; recursoID: number }>(
      this.url + "update-fitosanitario/",
      params,
      { headers: headers }
    );
  }

  /**
   * Obtiene los problemas fito y recursos dependiendo del id_cultivo
   * @param id_cultivo de la parcela
   */
  getProblemasFito(id_cultivo: number, num_socio: number) {
    return this._http.get<ResponseProblemasFito>(
      this.url + "tareas/get-problemas-fito/" + id_cultivo
    );
  }

  /**
   * Metodo para obtener los equipos de un socio
   * @param num_socio Número de Socio
   */
  getServicios(num_socio: number) {
    let user_login = this.us.getUserLogin();
    let aux = num_socio;
    if (user_login) {
      aux = user_login.num_socio;
    }
    return this._http.get<ResponseServicios>(this.url + "get-servicios/" + aux);
  }

  /**
   * Metodo para crear un nuevo servicio
   * @param fitosanitario Equipo a crear
   */
  createServicio(servicio: Servicio) {
    let params = JSON.stringify(servicio);
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    return this._http.post<{ status: string; recursoID: number }>(
      this.url + "save-servicio/",
      params,
      { headers: headers }
    );
  }

  /**
   * Metodo para Actualizar Servicio
   * @param servicio Servicio a Actualizar
   * @param recurso Recurso a Actualizar
   */
  updateServicio(servicio: Servicio, recurso: Recurso) {
    var aux = {
      servicio: servicio,
      recurso: recurso,
    };
    let params = JSON.stringify(aux);
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    return this._http.put<{ status: string; recursoID: number }>(
      this.url + "update-servicio/",
      params,
      { headers: headers }
    );
  }

  /**
   * Metodo que obtiene las tareas (Globales)
   * @param num_socio número del socio
   */
  getTareas(num_socio: number) {
    let user_login = this.us.getUserLogin();
    let aux = num_socio;
    if (user_login) {
      aux = user_login.num_socio;
    }
    return this._http
      //.get<ResponseTareas>(this.url + `tareas/get-tareas/${aux}`)
      .get<ResponseTareas>(this.url + `tareas/get-tareas/`)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  /**
   * Metodo que obtiene las tareas (Globales con las parcelas asociadas)
   * @param num_socio número del socio
   */
  getTareas_with_Parcelas(num_socio: number) {
    let user_login = this.us.getUserLogin();
    let aux = num_socio;
    if (user_login) {
      aux = user_login.num_socio;
    }
    return this._http
      //.get<ResponseTareas>(this.url + `tareas/get-tareas-with-parcelas/${aux}`)
      .get<ResponseTareas>(this.url + `tareas/get-tareas-with-parcelas/`)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  /**
   * Metodo que obtiene las tareas (Globales con las parcelas asociadas)
   * @param id_socio id del socio
   */
  getTareas_with_ParcelasCampana(id_socio: number, year: number) {
    return this._http
      .get<ResponseTareas>(
        this.url +
        `tareas/get-tareas-with-parcelas-campana/${id_socio}/${year}`
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }
  /**
   * Metodo que obtiene resumen de las tareas 
   * @param id_socio id del socio
   */
  getTareas_ResumenExel(id_socio: number, year: number) {
    return this._http
      .get<ResponseTareas>(
        this.url +
        `tareas/get-tareas-resumen-exel/${id_socio}/${year}`
        //`tareas/get-tareas-resumen-exel/${year}`
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  } 

  /**
   * Metodo para ActualizarTarea
   * @param tarea Servicio a Actualizar
   */
  updateTarea(tarea: Tarea) {
    let params = JSON.stringify(tarea);
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    return this._http.put<any>(this.url + "tareas/update-tarea/", params, {
      headers: headers,
    });
  }

  /**
   * Metodo para Crear una Tarea
   * @param tarea Servicio a Actualizar
   */
  createTarea(tarea: TareaCreate) {
    let params = JSON.stringify(tarea);
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    return this._http.post<any>(this.url + "tareas/save-tarea/", params, {
      headers: headers,
    });
  }

  /**
   * Metodo que obtiene los detalles de las tareas
   * @param num_socio número del socio
   */
  getDetallesTareas(num_socio: number) {
    let user_login = this.us.getUserLogin();
    let aux = num_socio;
    if (user_login) {
      aux = user_login.num_socio;
    }
    return this._http
      .get<ResponseDetallesTareas>(
        //this.url + `tareas/get-detalles-tareas/${aux}`
        this.url + `tareas/get-detalles-tareas/`
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }
  /**
   * Metodo que obtiene los Datos Especificos de las tareas
   * @param id_tarea identificador de la tarea
   */
  getDatosEspecificosTareas(id_tarea: number) {    
    return this._http
      .get<any>(
        this.url + `tareas/get-datos-especificos/${id_tarea}`
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  /**
   * Metodo que obtiene los detalles de las tareas
   * @param num_orden número del socio
   */
  getDetallesTareasCampana(num_socio: number, year: number) {
    return this._http
      .get<ResponseDetallesTareas>(
        this.url + `tareas/get-detalles-tareas-campana/${num_socio}/${year}`
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }
  /**
   * Metodo para obtener los recursos de un socio
   * @param num_socio Número de Socio
   */
  /*getTareasStore(num_socio: number) {
      return this.dataTarea;
    }*/
  /** Observable MenuMap - Controlador de Capas */
  /*enviarTareas (data : Tarea[]) {
      this.dataTarea = [...data];
      this.$tareasSubject.next(data);
    }*/

  /**
   * Metodo para obtener los recursos de un socio
   * @param num_socio Número de Socio
   */
  /*getTareasDetalles(num_socio : number) {
      //return this._http.get<Tarea[]>(this.url + "get-recursos/" + num_socio);
      return this.dataTareaDetalle;
    }*/
  /** Observable MenuMap - Controlador de Capas */
  /*enviarTareasDetalle (data : TareaDetalle[]) {
      this.dataTareaDetalle = data;
      this.$tareasDetallesSubject.next(data);
    }*/

  /**
   * Metodo para obtener los articulos en Stock
   */
  getStock(id_c_regante: number) {
    return this._http
      .get<ResponseStock>(this.url + "stock/get-articulos/" + id_c_regante)
      .pipe(
        map((response) => {
          const articulos = response.articulos;
          return articulos;
        })
      );
  }

  /**
   * Metodo para obtener el proveedor de un articulo en stock
   * @param id_articulo ID del articulo de quien busca proveedor
   */
  getProveedor(id_articulo: number) {
    return this._http
      .get<ResponseProveedor>(this.url + "stock/get-proveedor/" + id_articulo)
      .pipe(
        map((response) => {
          let proveedores = response.proveedores;
          return proveedores;
        })
      );
  }

  /**
   * Metodo para obtener todos los proveedores de una comunidad regante
   * @param id_articulo ID del articulo de quien busca proveedor
   */
  getAllProveedores(id_c_regante: number) {
    return this._http
      .get<ResponseProveedor>(
        this.url + "stock/get-all-proveedores/" + id_c_regante
      )
      .pipe(
        map((response) => {
          const proveedores = response.proveedores;
          return proveedores;
        })
      );
  }

  /**
   * Metodo para obtener lista de categorias
   * @param id_articulo ID del articulo de quien busca proveedor
   */
  getCategorias() {
    return this._http
      .get<ResponseCategorias>(this.url + "stock/get-categorias")
      .pipe(
        map((response) => {
          const categorias = response.categorias;
          return categorias;
        })
      );
  }

  /**
   * Metodo para obtener lista de de los tipos de elementos por comunidad regante
   * @param id_c_regante ID de la comunidad regante
   */
  getTipoElementos(id_c_regante: number) {
    return this._http
      .get<ResponseTipoElementos>(
        this.url + "stock/get-tipo-elementos/" + id_c_regante
      )
      .pipe(
        map((response) => {
          const elementos = response.elementos;
          return elementos;
        })
      );
  }

  /**
   * Metodo para Actualizar Articulo Stock
   * @param articulo Articulo a Actualizar
   * @param recurso Recurso a Actualizar
   */
  updateArticulo(articulo: Articulo, newProveedor: boolean) {
    const aux = {
      articulo,
      nuevoProveedor: newProveedor,
    };

    const params = JSON.stringify(aux);
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this._http.put<{ status: string; articuloID: number }>(
      this.url + "stock/update-articulo",
      params,
      { headers }
    );
  }

  /**
   * Metodo para obtener los pedidos totales segun el estado
   * 0=realizado, 1 en transito, 2,  3 recibido, 4 facturado, 5 Cancelado
   * @param id_estado ID del estado a consultar
   * @param id_articulo ID del articulo
   * @param id_c_regante ID de la comunidad regante
   */
  getTotalPedidosEstado(
    id_estado: number,
    id_articulo: number,
    id_c_regante: number
  ) {
    return this._http
      .get<ResponsePedidosTotalEstado>(
        this.url +
        `stock/get-pedidos-total/${id_estado}/${id_articulo}/${id_c_regante}`
      )
      .pipe(
        map((response) => {
          const estado = response.estado;
          return estado;
        })
      );
  }

  /**
   * Metodo para crear un nuevo Articulo en Stock
   * @param Articulo Equipo a crear
   */
  createArticulo(articulo: Articulo) {
    let params = JSON.stringify(articulo);
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    return this._http.post<{ status: string; recursoID: number }>(
      this.url + "stock/save-articulo",
      params,
      { headers }
    );
  }

  /**
   * Metodo eliminar un articulo, si no lo elimina lo oculta
   * @param id_articulo ID del articulo de quien busca proveedor
   */
  deleteArticulo(id_articulo: number) {
    return this._http
      .get<any>(this.url + "stock/delete-articulo/" + id_articulo)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  /**
   * Metodo para retirar existencias de un articulo
   * @param retirar datos del retiro
   * @param existencias existencias actuales del articulo
   */
  retirarExistenciaArticulo(retirar: any, existencias: string) {
    var aux = {
      retirar,
      existencias,
    };
    let params = JSON.stringify(aux);
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    return this._http.post<{ status: string; retiroID: number }>(
      this.url + "stock/retirar-articulo/",
      params,
      { headers }
    );
  }

  /**
   * Metodo para Actualizar Articulo Stock
   * @param categoria categoria a Actualizar
   */
  updateCategoria(categoria: Categoria) {
    const params = JSON.stringify(categoria);
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this._http.put<{ status: string; mensaje: any }>(
      this.url + "stock/update-categoria",
      params,
      { headers }
    );
  }

  /**
   * Metodo para crear categoria Stock
   * @param categoria Categoria a crear
   */
  createCategoria(categoria: Categoria) {
    const params = JSON.stringify(categoria);
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this._http.post<{ status: string; mensaje: any }>(
      this.url + "stock/save-categoria",
      params,
      { headers }
    );
  }

  /**
   * Metodo eliminar una categoria
   * @param id_categoria ID de la categoria
   */
  deleteCategoria(id_categoria: number) {
    return this._http
      .get<any>(this.url + "stock/delete-categoria/" + id_categoria)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  /**
   * Metodo que obtiene los proveedores con los totales facturados
   * @param id_c_regante Comunidad regante
   */
  getProveedoresTable(id_c_regante: number) {
    return this._http
      .get<any>(this.url + "stock/get-proveedores-table/" + id_c_regante)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  /**
   * Metodo para Actualizar Proveedor Stock
   * @param proveedor Proveedor a Actualizar
   */
  updateProveedor(proveedor: any) {
    const params = JSON.stringify(proveedor);
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this._http.put<{ status: string; mensaje: any }>(
      this.url + "stock/update-proveedor",
      params,
      { headers }
    );
  }

  /**
   * Metodo para Crear Proveedor en  Stock
   * @param proveedor Proveedor a crear
   */
  createProveedor(proveedor: any) {
    const params = JSON.stringify(proveedor);
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this._http.post<{ status: string; mensaje: any }>(
      this.url + "stock/save-proveedor",
      params,
      { headers }
    );
  }

  /**
   * Metodo eliminar un Proveedor
   * @param id_proveedor ID del proveedor
   */
  deleteProveedor(id_proveedor: number) {
    return this._http
      .get<any>(this.url + "stock/delete-proveedor/" + id_proveedor)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  /**
   * Metodo que obtiene los pedidos con la informacion referente a ellos
   * @param id_c_regante Comunidad regante
   */
  getAllPedidos(id_c_regante: number) {
    return this._http
      .get<any>(this.url + "stock/get-all-pedidos/" + id_c_regante)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  /**
   * Metodo que obtiene los Articulos que forman un pedido
   * @param id_c_regante Comunidad regante
   * @param id_pedido id del pedido
   */
  getArticulosPedidos(id_pedido: number, id_c_regante: number) {
    return this._http
      .get<any>(
        this.url + `/stock/get-articulos-pedidos/${id_pedido}/${id_c_regante}`
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  /**
   * Sección de Recursos (Base de datos gestion4piot )
   */
  /**
   * Metodo para obtener  el personal de un socio
   * @param num_socio Número de Socio
   */
  getRPersonal(num_socio: number) {
    let user_login = this.us.getUserLogin();
    let aux = num_socio;
    if (user_login) {
      aux = user_login.num_socio;
    }
    return this._http.get<ResponseRecursoPersonal>(
      this.url + "recursos/get-personal/"/* + aux*/
    );
  }
  /**
   * Metodo para obtener  los equipos de un socio
   * @param num_socio Número de Socio
   */
  getREquipos(num_socio: number) {
    let user_login = this.us.getUserLogin();
    let aux = num_socio;
    if (user_login) {
      aux = user_login.num_socio;
    }
    return this._http.get<ResponseRecursoEquipos>(
      this.url + "recursos/get-equipos/" /*+ aux*/
    );
  }
  /**
   * Metodo para obtener  los fitosanitarios de un socio
   * @param num_socio Número de Socio
   */
  getRFitosanitarios(num_socio: number) {
    let user_login = this.us.getUserLogin();
    let aux = num_socio;
    if (user_login) {
      aux = user_login.num_socio;
    }
    return this._http.get<ResponseRecursoFitos>(
      this.url + "recursos/get-fitosanitarios/" /*+ aux*/
    );
  }
  /**
   * Metodo para obtener los fertilizantes de un socio
   * @param num_socio Número de Socio
   */
  getRFertilizantes(num_socio: number) {
    let user_login = this.us.getUserLogin();
    let aux = num_socio;
    if (user_login) {
      aux = user_login.num_socio;
    }
    return this._http.get<ResponseRecursoFertilizantes>(
      this.url + "recursos/get-fertilizantes/" /*+ aux*/
    );
  }
  /**
   * Metodo para obtener las semillas de un socio
   * @param num_socio Número de Socio
   */
  getRSemillas(num_socio: number) {
    let user_login = this.us.getUserLogin();
    let aux = num_socio;
    if (user_login) {
      aux = user_login.num_socio;
    }
    return this._http.get<ResponseRecursoSemillas>(
      this.url + "recursos/get-semillas/" /*+ aux*/
    );
  }
  /**
   * Metodo para obtener las aguas de riego de un socio
   * @param num_socio Número de Socio
   */
  getRRiego(num_socio: number) {
    let user_login = this.us.getUserLogin();
    let aux = num_socio;
    if (user_login) {
      aux = user_login.num_socio;
    }
    return this._http.get<ResponseRecursoAguaDeRiego>(
      this.url + "recursos/get-aguas/" /*+ aux*/
    );
  }
  /**
   * Metodo para obtener los servicios de un socio
   * @param num_socio Número de Socio
   */
  getRServicios(num_socio: number) {
    let user_login = this.us.getUserLogin();
    let aux = num_socio;
    if (user_login) {
      aux = user_login.num_socio;
    }
    return this._http.get<ResponseRecursoServicios>(
      this.url + "recursos/get-servicios/"/* + aux*/
    );
  }
  /**
   * Metodo para obtener  los equipos de un socio
   * @param num_socio Número de Socio
   */
  getRLocales(num_socio: number) {
    let user_login = this.us.getUserLogin();
    let aux = num_socio;
    if (user_login) {
      aux = user_login.num_socio;
    }
    return this._http.get<ResponseRecursoLocales>(
      this.url + "recursos/get-locales/"/* + aux*/
    );
  }
  /**
   * Metodo para obtener  los equipos de un socio
   * @param num_socio Número de Socio
   */
  getRVehiculos(num_socio: number) {
    let user_login = this.us.getUserLogin();
    let aux = num_socio;
    if (user_login) {
      aux = user_login.num_socio;
    }
    return this._http.get<ResponseRecursoVehiculos>(
      this.url + "recursos/get-vehiculos/"/* + aux*/
    );
  }

  /**
   * Metodo para obtener la relacion Tareas - Fito - Tarea
   * @param id_tarea ID de la tarea
   */
  getTareasFitoTarea(id_tarea: number) {
    return this._http.get<ResponseTareasFitoTarea>(
      this.url + "tareas/get-tareas-fito-tarea/" + id_tarea
    );
  }

  /**
   * Metodo para crear un nuevo personal
   * @param personal Equipo a crear
   */
  /*createPersonal(personal: Personal) {
      let params = JSON.stringify(personal);
      let headers = new HttpHeaders().set('Content-Type', 'application/json');
      return this._http.post<{status: string, recursoID: number}>(this.url + 'save-personal/', params, { headers: headers });
    }*/

  /**
   * Metodo para Actualizar personal
   * @param personal Personal a Actualizar
   * @param recurso Recurso a Actualizar
   */
  /*updatePersonal(personal: Personal, recurso: Recurso) {
      var aux = {
        personal: personal,
        recurso: recurso
      }
      let params = JSON.stringify(aux);
      let headers = new HttpHeaders().set('Content-Type', 'application/json');
      return this._http.put<{ status: string, recursoID: number }>(this.url + 'update-personal/', params, { headers: headers });
    }*/

  /**
   * MODULO 4SIG DATA
   * Peticiones para el Módulo Cuaderno de Campo
   */
  /**
   * Metodo para crear GEORASTER a partir de un fichero GeoTIF
   */
  getGeoTIFF$ = (
    data: { fecha: string; tipo: string },
    capa: string
  ): Observable<any> => {
    const obs$ = new Observable((observer) => {
      fetch(this.url + "get-tiff-" + capa + "/" + data.fecha + "/" + data.tipo)
        .then((response) => {
          if (response.status == 404) {
            throw "Error - No existe el fichero en el servidor del archivo GeoTIF (EVI)";
          } else {
            return response.arrayBuffer();
          }
        })
        .then((arrayBuffer) => {
          parse_georaster(arrayBuffer).then((georaster) => {
            var layer = new GeoRasterLayer({
              georaster: georaster,
              opacity: 0.7,
              resolution: 256,
            });
            observer.next(layer);
            observer.complete();
          });
        })
        .catch((error) => {
          console.log(
            error +
            " Para la peticion " +
            this.url +
            "get-tiff-" +
            capa +
            "/" +
            data.fecha +
            "/" +
            data.tipo
          );
        });
    });
    return obs$;
  };

  /**
   * Realiza una peticion tipo fetch al servidor
   * @param data.fecha - Fecha correspodiente del fichero GeoTIFF
   * @param data.tipo - parcelas (Solo parcelas) o recorte_parcelas (Bloque Completo)
   * @param data.capa - ndvi || ndwi || evi || indiceHumedad
   */
  getGeoTIFF_Image$ = (
    data: { fecha: string; tipo: string },
    capa: string
  ): Observable<any> => {
    const obs$ = new Observable((observer) => {
      fetch(this.url + "get-tiff-" + capa + "/" + data.fecha + "/" + data.tipo)
        .then((response) => {
          if (response.status == 404) {
            throw "Error - No existe el fichero en el servidor del archivo GeoTIF (EVI)";
          } else {
            return response.arrayBuffer();
          }
        })
        .then((data) => {
          observer.next(data);
          observer.complete();
        })
        .catch((error) => {
          console.log(
            error +
            " Para la peticion " +
            this.url +
            "get-tiff-" +
            capa +
            "/" +
            data.fecha +
            "/" +
            data.tipo
          );
        });
    });
    return obs$;
  };
  /**
   * Metodo que obtiene las Ventas por año asociadas a un socio
   * @param id_socio id del socio
   */
  getVentas(id_socio: number, year: number) {
    return this._http.get<ResponseVentas>(this.url + `cuaderno/get-ventas/${id_socio}/${year}`);
  }
}
