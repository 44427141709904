import { UserLogin } from './model/user-login.model';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { MenuController } from '@ionic/angular';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { DataService } from './services/data/data.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { NavigationStart, Event, Router, NavigationEnd } from '@angular/router';
import { ThemeService } from './services/theme.service';
import { UrlService } from './services/url.service';
import { filter } from 'rxjs/operators';

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent implements OnInit, AfterViewInit {
  previousUrl: string = null;
  currentUrl: string = null;

  constructor(
    private platform: Platform,
    private router: Router,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private menu : MenuController,
    private dataServices: DataService,
    private themeService: ThemeService,
    iconRegistry: MatIconRegistry, sanitizer: DomSanitizer,
    private mps : MatPaginatorIntl,
    private urlService: UrlService
  ) {
    this.initializeApp();
    iconRegistry.addSvgIcon(
      'parcelas',
      sanitizer.bypassSecurityTrustResourceUrl('/assets/icon/cuaderno-campo/parcelas.svg')
    );
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        this.themeService.setThemeByRoute(event.url);
      }
    });
  }

  ngOnInit() {
    this.openFirst();
    this.initGlobalData();

    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.previousUrl = this.currentUrl;
      this.currentUrl = event.url;
      this.urlService.setPreviousUrl(this.previousUrl);
    });

    // Seteando datos de usuario QUEMADOS del socio 2
    // Registros para probar
    // 1.- Usuario Agricultor
    // REAL -> { id: 3, num_socio: 2, rol: 3, id_comunidad_regantes: 1 } | {"id":3,"num_socio":2,"rol":3, "id_comunidad_regantes": 1}
    // DEMO -> { id: 369, num_socio: 2 rol: 3 : id_comunidad_regantes: 32 } | {"id":369,"num_socio":2,"rol":3, "id_comunidad_regantes": 32}
    // 2.- Usuario Tecnico
    // REAL -> { id: 5, num_socio: 5, rol: 2, id_comunidad_regantes: 1 } | {"id":5,"num_socio":5,"rol":2, "id_comunidad_regantes": 1}
    // DEMO -> { id: 370, num_socio: 3, rol: 2, id_comunidad_regantes: 32 } | {"id":370,"num_socio":3,"rol":2, "id_comunidad_regantes": 32}
    // 3.- Usuario Administrador
    // REAL -> { id: 366, num_socio: 391, rol: 1, id_comunidad_regantes: 1 } | {"id":366,"num_socio":391,"rol":1, "id_comunidad_regantes": 1}
    // DEMO -> NO HAY

    // Ruta ejemplo para probar directamente el redireccionador
    // http://localhost:4200/map/366:391:1:1:9ac48d7a14a2b05e3b452f18a90c5b728cd34061
    // http://localhost:4200/map/368:1:2:32:96a5dfc3212177eb2604f4843485702fc1210885
/*     const aux = sessionStorage.getItem('user_login');
    let user_login: UserLogin;
    if (!aux) {
      user_login = {
        id:                    366,
        num_socio:             391,
        rol:                   1,
        id_comunidad_regantes: 1,
        token: '',
        entrada: 'Angular'
      } 
    } else {
      user_login = JSON.parse(aux);
    }
    sessionStorage.setItem("user_login", JSON.stringify(user_login));  */

    const titulo = document.getElementsByClassName('title-page')[0];
    titulo.innerHTML = titulo.innerHTML + " - " + environment.name;
  }

  ngAfterViewInit(): void {
    // Inicializa los labels por defecto para la paginacion de las Material Angular Table
    this.mps.itemsPerPageLabel = 'Paginación';
    this.mps.nextPageLabel = 'Siguente página';
    this.mps.previousPageLabel = 'Anterior página';
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  openFirst() {
    this.menu.enable(true, 'first');
    this.menu.open('first');
  }

  openMenu() {
    this.menu.enable(true, 'first');
    this.menu.open('first');
  }

  initGlobalData() {
  }

}
