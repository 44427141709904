import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router, UrlSegment } from '@angular/router';
import { Observable } from 'rxjs';
import { UserLogin } from 'src/app/model/user-login.model';
import { environment } from 'src/environments/environment';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ValidarModoGuard implements CanActivate, CanLoad {

  // LEER
  /**
   * Este guard esta pensado para GIS, pero puede aplicarse a cualquier ruta
   * En el modo PHP solo se permitirá el acceso a rutas específicas 
   */
  
  private routes_data_php = ['4sig', 'cuaderno-campo', 'gestion', 'hidraulico', 'energia', 'sensores'];

  constructor(
    private authService: AuthService,
    private router: Router
  ){}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | boolean {
    const segment = route.url.length ? route.url[0].path : '';
    const user_login = this.authService.getUserLogin();
    return this.canAccess(user_login, segment);
  }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | boolean {
    const segment = segments.length ? segments[0].path : '';
    const user_login = this.authService.getUserLogin();
    return this.canAccess(user_login, segment);
  }

  canAccess(user_login: UserLogin, segment: string) {
    if (!user_login) {
      return true;
    }
    // Modo angular, todas las rutas permitidas
    if (user_login.entrada == 'Angular') {
      return true;
    }
    // Si es true => Esta ruta esta permitida para modo PHP
    else if (user_login.entrada == 'PHP') {
      return true;
    }
    // El modo de entrada tiene que estar definido estrictamente
    else {
      window.location.href = "/login/";
      alert('Error, modo de entrada no definido, contacte con el Administrador');
      return false;
    }
  }

}
