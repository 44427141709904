export class Noticias {

    static generateNoticias(obj: Object) {
        return new Noticias(
            obj['tipo'],
            obj['title'],
            obj['asunto'],
            obj['descripcion'],
            new Date(obj['fecha'])
        )
    }

    constructor(
        public tipo: number,
        public title: string,
        public asunto: string,
        public descripcion:string[],
        public fecha: Date
    ){}
}