export interface DatosGenerales {
    titular: Titular;
    empresa: Empresa;
}


export class Titular {

    static generateTitular(obj: Object) {
        return new Titular(
            obj['direccion'],
            obj['email'],
            obj['localidad'],
            obj['nif'],
            obj['nombres'],
            obj['provincia'],
            obj['telefono'],
            obj['telefono_movil'],
            obj['tipo_representacion'],
        )
    }

    constructor(
        public direccion: string,
        public email: string,
        public localidad: string,
        public nif: string,
        public nombres: string,
        public provincia: string,
        public telefono: string,
        public telefono_movil: string,
        public tipo_representacion: TipoRepresentacion,
    ) { }
}

export enum TipoRepresentacion {
    "Técnico" = 0,
    "Titular" = 1,
    "Representante" = 2
}


export class Empresa {

    static generateEmpresa(obj: Object) {
        return new Empresa(
            obj['direccion'] ? obj['direccion'] : '',
            obj['email'] ? obj['email'] : '',
            obj['localidad'] ? obj['localidad'] : '',
            obj['nif'] ? obj['nif'] : '',
            obj['nombres'] ? obj['nombres'] : '',
            obj['provincia'] ? obj['provincia'] : '',
            obj['registro_autonomo'] ? obj['registro_autonomo'] : '',
            obj['registro_nacional'] ? obj['registro_nacional'] : '',
            obj['telefono'] ? obj['telefono'] : 0,
            obj['telefono_movil'] ? obj['telefono_movil'] : 0,
        )
    }

    constructor(
        public direccion: string,
        public email: string,
        public localidad: string,
        public nif: string,
        public nombres: string,
        public provincia: string,
        public registro_autonomo: string,
        public registro_nacional: string,
        public telefono: string,
        public telefono_movil: string,
    ) { }
}