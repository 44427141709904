import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UtilsService } from '../services/utils.service';
import { AuthService } from '../pages/login/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {

  constructor(
    private us: UtilsService,
    private authService: AuthService
  ) { }

  /**
   * Metodo que se ejecuta con cada petición HTTP
   * Agregaremos la información necesaria del usuario en los Headers
   * @param req 
   * @param next 
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let user = this.us.getUserLogin();

    if (req.headers.get('Content-Type') === 'multipart/form-data') {
      const headers = new HttpHeaders({
        'user_login': JSON.stringify(user) || '',
        'session_id': this.authService.getToken(),
        'Session-Id': this.authService.getToken()
      });
      let reqClone = req.clone({
        headers
      });
      return next.handle(reqClone);
    }
    const headers = new HttpHeaders({
      'user_login': JSON.stringify(user) || '',
      'Content-Type': 'application/json',
      'session_id': this.authService.getToken(),
      'Session-Id': this.authService.getToken()
    });
    let reqClone = req.clone({
      headers
    });
    return next.handle(reqClone);
  }
}
