// Modelos Globales
export { ResponseParcelas, Parcela, ResponseParcelasCultivos, Parcela_Cultivo } from "./parcelas.model";
export { ResponsePoligonos, Poligono } from "./poligonos.model";
export { ResponseLocalidades, Localidad, ResponseMunicipios, Municipio } from "./localidades.model";
export { ResponseProvincias, Provincia } from "./provincias.model";


export { ResponseCups, CupsReq } from "./cups.model";
export { ResponseHidra ,Pozo, Balsa, Cabezal, Hidrante } from "./hidraulica.model";
export { Noticias } from "./noticias.model";
export { Properties } from "./properties";
export { MenuOpcion, MenuOpcionLateral, MenuMap } from "./menuOptiones.model";
export { ResponseSocio, Socio } from "./socio.model"; 
export { DatosGenerales, Titular, Empresa } from "../pages/cuaderno-campo/model/datosGenerales.model";
export { ResponseParcelasCuaderno, ParcelaCuaderno } from "../pages/cuaderno-campo/model/cuaderno-parcelas.model";
export { ResponseTurnosRiego } from "./turnos-de-riego.model";
export { 
    ResponseRecursos, Recurso, 
    ResponseEquipos, Equipo, 
    ResponsePersonal, Personal, 
    ResponseFertilizantes, Fertilizante, 
    ResponseFitosanitarios, Fitosanitario,
    ResponseServicios, Servicio 
} from "./gestion.model";
export { 
    ResponseQampo,
    Estacion,
    Sensor,
    Medida
} from "./estacionesMeteorologicas.model";
export { ResponseFeaturesColletion, FeaturesColletion, Feature, FeatureProperties } from "./feacture-colletion.model";
export { Tarea, DetallesTarea } from './tarea.model';


// Modelos para Modulo de Recursos, Modulo de Tareas, DataService
export { 
    ResponseRecursoPersonal, Recurso_Personal,
    ResponseRecursoEquipos, Recurso_Equipo,
    ResponseRecursoFitos, Recurso_Fito,
    ResponseRecursoFertilizantes, Recurso_Fertilizante,
    ResponseRecursoServicios, Recurso_Servicio,
    Recursos
} from './recursos.model';


// Modelo para localidades sigpac
export { ResponseSigpac, Sigpac_data } from './sigpac.model';