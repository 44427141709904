import { ITheme } from '../model/theme.interface';
export const THEMES: ITheme = {
  default: {
    bgBackFull: '#ccc',
    bgFrontFull: '#fff',
    primaryColor: '#333',
    primaryDarkColor: '#777',
    primaryLightColor: '#111',
    boldColor: '#222',
    shadowColor: 'rgba(0,0,0,.5)',
    cornerRadius: '1.625rem',
  },
  hidraulica: {
    bgBackFull: '#ddeafa',
    bgFrontFull: '#e7f1fd',
    shadowColor: 'rgba(89, 159, 252, 0.38)',
    primaryColor: '#599ffc', 
    primaryDarkColor: '#2963B1',
    primaryLightColor: '#599ffc',
    boldColor: '#2963B1',
    cornerRadius: '1.625rem',
  },
  medioAmbiente: {
    bgBackFull: '#FCF5F0',
    bgFrontFull: '#F2E4DC',
    bgLight: '#F4E8E1',
    bgLighter: '#F7E7DE',
    shadowColor: '#F2E4DC',
    primaryColor: '#C9A996', 
    primaryDarkColor: '#9A7864',
    primaryLightColor: '#C9A996',
    boldColor: '#674B3A',
    cornerRadius: '1.625rem',
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightSemiBold: 600,
    fontWeightBold: 700,
  }
};