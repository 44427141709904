export class MenuOpcion {

    static generateOpcion(obj: Object) {
        return new MenuOpcion(
            obj['name'],
            obj['title'],
            obj['active'],
            obj['normal'],
            obj['hover'],
            obj['redirectTO'],
            obj['processRedirect'],
            obj['children']
        )
    }

    constructor(
        public name: string,
        public title: string,
        public active: boolean,
        public normal: string,
        public hover: string,
        public redirectTO: string,
        public processRedirect: boolean,
        public children: MenuOpcion[]
    ) { }
}


export class MenuOpcionLateral {

    static generateOpcion(obj: Object) {
        return new MenuOpcionLateral(
            obj['name'],
            obj['title'],
            obj['active'],
            obj['normal'],
            obj['hover'],
            obj['redirectTO'],
            obj['children']
        )
    }

    constructor(
        public name: string,
        public title: string,
        public active: boolean,
        public normal: string,
        public hover: string,
        public redirectTO: string,
        public children: MenuOpcionLateral[]
    ) { }
}

export class MenuMap {

    static generateOpcion(obj: Object) {
        return new MenuMap(
            obj['name'],
            obj['title'],
            obj['active'],
            obj['selected'],
            obj['normal'],
            obj['hover'],
            obj['redirectTO'],
            obj['children']
        )
    }

    constructor(
        public name: string,
        public title: string,
        public active: boolean,
        public selected: boolean,
        public normal: string,
        public hover: string,
        public redirectTO: string,
        public children: MenuMap[]
    ) { }
}


