import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes, CanActivate } from '@angular/router';
import { ValidarModoGuard } from './pages/login/guards/validar-modo.guard';
import { ValidarTokenGuard } from './pages/login/guards/validar-token.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule),
    canActivate: [ ValidarModoGuard ],
    canLoad: [ ValidarModoGuard ]
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule),
    canActivate: [ ValidarTokenGuard, ValidarModoGuard ],
    canLoad: [ ValidarTokenGuard, ValidarModoGuard ]
  },
  {
    path: 'news',
    loadChildren: () => import('./pages/news/news.module').then( m => m.NewsPageModule),
    canActivate: [ ValidarTokenGuard, ValidarModoGuard ],
    canLoad: [ ValidarTokenGuard, ValidarModoGuard ]
  },
  {
    path: 'cuadro-de-mando',
    loadChildren: () => import('./pages/cuadro-de-mando/cuadro-de-mando.module').then( m => m.CuadroDeMandoPageModule),
    canActivate: [ ValidarTokenGuard, ValidarModoGuard ],
    canLoad: [ ValidarTokenGuard, ValidarModoGuard ]
  },
  {
    path: 'ag-de-precision',
    loadChildren: () => import('./pages/ag-de-precision/ag-de-precision.module').then( m => m.AgDePrecisionPageModule),
    canActivate: [ ValidarTokenGuard, ValidarModoGuard ],
    canLoad: [ ValidarTokenGuard, ValidarModoGuard ]
  },
  {
    path: 'agricultura-de-precision',
    loadChildren: () => import('./pages/agricultura-de-precision/agricultura-de-precision.module').then( m => m.AgriculturaDePrecisionPageModule),
    canActivate: [ ValidarTokenGuard, ValidarModoGuard ],
    canLoad: [ ValidarTokenGuard, ValidarModoGuard ]
  },
  {
    path: 'cuaderno-campo',
    loadChildren: () => import('./pages/cuaderno-campo/cuaderno/cuaderno.module').then( m => m.CuadernoPageModule),
    canActivate: [ ValidarTokenGuard, ValidarModoGuard ],
    canLoad: [ ValidarTokenGuard, ValidarModoGuard ]
  },  
  // Nuevo modulo de cuaderno de campo 
  {
    path: 'cuaderno-de-campo-escritorio',
    loadChildren: () => import('./pages/cuaderno-de-campo-escritorio/cuaderno-de-campo-escritorio.module').then( m => m.CuadernoDeCampoEscritorioPageModule),
    canActivate: [ ValidarTokenGuard, ValidarModoGuard ],
    canLoad: [ ValidarTokenGuard, ValidarModoGuard ]
  },
  {
    path: 'cuaderno-de-campo',
    loadChildren: () => import('./pages/cuaderno-de-campo/cuaderno-de-campo.module').then( m => m.CuadernoDeCampoPageModule),
    canActivate: [ ValidarTokenGuard, ValidarModoGuard ],
    canLoad: [ ValidarTokenGuard, ValidarModoGuard ]
  },
  {
    path: 'cuaderno-de-campo/recursos',
    loadChildren: () => import('./pages/cuaderno-de-campo/recursos/recursos.module').then( m => m.RecursosPageModule),
    canActivate: [ ValidarTokenGuard, ValidarModoGuard ],
    canLoad: [ ValidarTokenGuard, ValidarModoGuard ]
  },
  {
    path: 'cuaderno-de-campo/recursos/parcelas',
    loadChildren: () => import('./pages/cuaderno-de-campo/recursos/parcelas/parcelas.module').then( m => m.ParcelasPageModule),
    canActivate: [ ValidarTokenGuard, ValidarModoGuard ],
    canLoad: [ ValidarTokenGuard, ValidarModoGuard ]
  },
  {
    path: 'cuaderno-de-campo/recursos/parcelas/crear-parcelas',
    loadChildren: () => import('./pages/cuaderno-de-campo/recursos/parcelas/crear-parcelas/crear-parcelas.module').then( m => m.CrearParcelasPageModule),
    canActivate: [ ValidarTokenGuard, ValidarModoGuard ],
    canLoad: [ ValidarTokenGuard, ValidarModoGuard ]
  },
  {
    path: 'cuaderno-de-campo/recursos/parcelas/modificar-parcelas/:id',
    loadChildren: () => import('./pages/cuaderno-de-campo/recursos/parcelas/modificar-parcelas/modificar-parcelas.module').then( m => m.ModificarParcelasPageModule),
    canActivate: [ ValidarTokenGuard, ValidarModoGuard ],
    canLoad: [ ValidarTokenGuard, ValidarModoGuard ]
  },
  {
    path: 'cuaderno-de-campo/recursos/equipos',
    loadChildren: () => import('./pages/cuaderno-de-campo/recursos/equipos/equipos.module').then( m => m.EquiposPageModule),
    canActivate: [ ValidarTokenGuard, ValidarModoGuard ],
    canLoad: [ ValidarTokenGuard, ValidarModoGuard ]
  },
  {
    path: 'cuaderno-de-campo/recursos/equipos/crear-equipos',
    loadChildren: () => import('./pages/cuaderno-de-campo/recursos/equipos/crear-equipos/crear-equipos.module').then( m => m.CrearEquiposPageModule),
    canActivate: [ ValidarTokenGuard, ValidarModoGuard ],
    canLoad: [ ValidarTokenGuard, ValidarModoGuard ]
  },
  {
    path: 'cuaderno-de-campo/recursos/equipos/modificar-equipos/:id',
    loadChildren: () => import('./pages/cuaderno-de-campo/recursos/equipos/modificar-equipos/modificar-equipos.module').then( m => m.ModificarEquiposPageModule),
    canActivate: [ ValidarTokenGuard, ValidarModoGuard ],
    canLoad: [ ValidarTokenGuard, ValidarModoGuard ]
  },
  {
    path: 'cuaderno-de-campo/recursos/personal',
    loadChildren: () => import('./pages/cuaderno-de-campo/recursos/personal/personal.module').then( m => m.PersonalPageModule),
    canActivate: [ ValidarTokenGuard, ValidarModoGuard ],
    canLoad: [ ValidarTokenGuard, ValidarModoGuard ]
  },
  {
    path: 'cuaderno-de-campo/recursos/personal/crear-personal',
    loadChildren: () => import('./pages/cuaderno-de-campo/recursos/personal/crear-personal/crear-personal.module').then( m => m.CrearPersonalPageModule),
    canActivate: [ ValidarTokenGuard, ValidarModoGuard ],
    canLoad: [ ValidarTokenGuard, ValidarModoGuard ]
  },
  {
    path: 'cuaderno-de-campo/recursos/personal/modificar-personal/:id',
    loadChildren: () => import('./pages/cuaderno-de-campo/recursos/personal/modificar-personal/modificar-personal.module').then( m => m.ModificarPersonalPageModule),
    canActivate: [ ValidarTokenGuard, ValidarModoGuard ],
    canLoad: [ ValidarTokenGuard, ValidarModoGuard ]
  },
  {
    path: 'cuaderno-de-campo/recursos/naves',
    loadChildren: () => import('./pages/cuaderno-de-campo/recursos/naves/naves.module').then( m => m.NavesPageModule),
    canActivate: [ ValidarTokenGuard, ValidarModoGuard ],
    canLoad: [ ValidarTokenGuard, ValidarModoGuard ]
  },
  {
    path: 'cuaderno-de-campo/recursos/naves/crear-naves',
    loadChildren: () => import('./pages/cuaderno-de-campo/recursos/naves/crear-naves/crear-naves.module').then( m => m.CrearNavesPageModule),
    canActivate: [ ValidarTokenGuard, ValidarModoGuard ],
    canLoad: [ ValidarTokenGuard, ValidarModoGuard ]
  },
  {
    path: 'cuaderno-de-campo/recursos/naves/modificar-naves/:id',
    loadChildren: () => import('./pages/cuaderno-de-campo/recursos/naves/modificar-naves/modificar-naves.module').then( m => m.ModificarNavesPageModule),
    canActivate: [ ValidarTokenGuard, ValidarModoGuard ],
    canLoad: [ ValidarTokenGuard, ValidarModoGuard ]
  },  
  {
    path: 'cuaderno-de-campo/recursos/servicios',
    loadChildren: () => import('./pages/cuaderno-de-campo/recursos/servicios/servicios.module').then( m => m.ServiciosPageModule),
    canActivate: [ ValidarTokenGuard, ValidarModoGuard ],
    canLoad: [ ValidarTokenGuard, ValidarModoGuard ]
  },
  {
    path: 'cuaderno-de-campo/recursos/servicios/crear-servicios',
    loadChildren: () => import('./pages/cuaderno-de-campo/recursos/servicios/crear-servicios/crear-servicios.module').then( m => m.CrearServiciosPageModule),
    canActivate: [ ValidarTokenGuard, ValidarModoGuard ],
    canLoad: [ ValidarTokenGuard, ValidarModoGuard ]
  },
  {
    path: 'cuaderno-de-campo/recursos/servicios/modificar-servicios/:id',
    loadChildren: () => import('./pages/cuaderno-de-campo/recursos/servicios/modificar-servicios/modificar-servicios.module').then( m => m.ModificarServiciosPageModule),
    canActivate: [ ValidarTokenGuard, ValidarModoGuard ],
    canLoad: [ ValidarTokenGuard, ValidarModoGuard ]
  },  
  {
    path: 'cuaderno-de-campo/recursos/vehiculos',
    loadChildren: () => import('./pages/cuaderno-de-campo/recursos/vehiculos/vehiculos.module').then( m => m.VehiculosPageModule),
    canActivate: [ ValidarTokenGuard, ValidarModoGuard ],
    canLoad: [ ValidarTokenGuard, ValidarModoGuard ]
  },
  {
    path: 'cuaderno-de-campo/recursos/vehiculos/crear-vehiculos',
    loadChildren: () => import('./pages/cuaderno-de-campo/recursos/vehiculos/crear-vehiculos/crear-vehiculos.module').then( m => m.CrearVehiculosPageModule),
    canActivate: [ ValidarTokenGuard, ValidarModoGuard ],
    canLoad: [ ValidarTokenGuard, ValidarModoGuard ]
  },
  {
    path: 'cuaderno-de-campo/recursos/vehiculos/modificar-vehiculos/:id',
    loadChildren: () => import('./pages/cuaderno-de-campo/recursos/vehiculos/modificar-vehiculos/modificar-vehiculos.module').then( m => m.ModificarVehiculosPageModule),
    canActivate: [ ValidarTokenGuard, ValidarModoGuard ],
    canLoad: [ ValidarTokenGuard, ValidarModoGuard ]
  },  
  {
    path: 'cuaderno-de-campo/recursos/inventario',
    loadChildren: () => import('./pages/cuaderno-de-campo/recursos/inventario/inventario.module').then( m => m.InventarioPageModule),
    canActivate: [ ValidarTokenGuard, ValidarModoGuard ],
    canLoad: [ ValidarTokenGuard, ValidarModoGuard ]
  }, 
  {
    path: 'cuaderno-de-campo/recursos/inventario/fertilizantes',
    loadChildren: () => import('./pages/cuaderno-de-campo/recursos/inventario/fertilizantes/fertilizantes.module').then( m => m.FertilizantesPageModule),
    canActivate: [ ValidarTokenGuard, ValidarModoGuard ],
    canLoad: [ ValidarTokenGuard, ValidarModoGuard ]
  },
  {
    path: 'cuaderno-de-campo/recursos/inventario/fertilizantes/crear-fertilizantes',
    loadChildren: () => import('./pages/cuaderno-de-campo/recursos/inventario/fertilizantes/crear-fertilizantes/crear-fertilizantes.module').then( m => m.CrearFertilizantesPageModule),
    canActivate: [ ValidarTokenGuard, ValidarModoGuard ],
    canLoad: [ ValidarTokenGuard, ValidarModoGuard ]
  },
  {
    path: 'cuaderno-de-campo/recursos/inventario/fertilizantes/modificar-fertilizantes/:id',
    loadChildren: () => import('./pages/cuaderno-de-campo/recursos/inventario/fertilizantes/modificar-fertilizantes/modificar-fertilizantes.module').then( m => m.ModificarFertilizantesPageModule),
    canActivate: [ ValidarTokenGuard, ValidarModoGuard ],
    canLoad: [ ValidarTokenGuard, ValidarModoGuard ]
  },
  {
    path: 'cuaderno-de-campo/recursos/inventario/semillas',
    loadChildren: () => import('./pages/cuaderno-de-campo/recursos/inventario/semillas/semillas.module').then( m => m.SemillasPageModule),
    canActivate: [ ValidarTokenGuard, ValidarModoGuard ],
    canLoad: [ ValidarTokenGuard, ValidarModoGuard ]
  },
  {
    path: 'cuaderno-de-campo/recursos/inventario/semillas/crear-semillas',
    loadChildren: () => import('./pages/cuaderno-de-campo/recursos/inventario/semillas/crear-semillas/crear-semillas.module').then( m => m.CrearSemillasPageModule),
    canActivate: [ ValidarTokenGuard, ValidarModoGuard ],
    canLoad: [ ValidarTokenGuard, ValidarModoGuard ]
  },
  {
    path: 'cuaderno-de-campo/recursos/inventario/semillas/modificar-semillas/:id',
    loadChildren: () => import('./pages/cuaderno-de-campo/recursos/inventario/semillas/modificar-semillas/modificar-semillas.module').then( m => m.ModificarSemillasPageModule),
    canActivate: [ ValidarTokenGuard, ValidarModoGuard ],
    canLoad: [ ValidarTokenGuard, ValidarModoGuard ]
  },
  {
    path: 'cuaderno-de-campo/recursos/agua-de-riego',
    loadChildren: () => import('./pages/cuaderno-de-campo/recursos/agua-de-riego/agua-de-riego.module').then( m => m.AguaDeRiegoPageModule),
    canActivate: [ ValidarTokenGuard, ValidarModoGuard ],
    canLoad: [ ValidarTokenGuard, ValidarModoGuard ]
  },
  {
    path: 'cuaderno-de-campo/recursos/agua-de-riego/crear-agua-de-riego',
    loadChildren: () => import('./pages/cuaderno-de-campo/recursos/agua-de-riego/crear-agua-de-riego/crear-agua-de-riego.module').then( m => m.CrearAguaDeRiegoPageModule),
    canActivate: [ ValidarTokenGuard, ValidarModoGuard ],
    canLoad: [ ValidarTokenGuard, ValidarModoGuard ]
  },
  {
    path: 'cuaderno-de-campo/recursos/agua-de-riego/modificar-agua-de-riego/:id',
    loadChildren: () => import('./pages/cuaderno-de-campo/recursos/agua-de-riego/modificar-agua-de-riego/modificar-agua-de-riego.module').then( m => m.ModificarAguaDeRiegoPageModule),
    canActivate: [ ValidarTokenGuard, ValidarModoGuard ],
    canLoad: [ ValidarTokenGuard, ValidarModoGuard ]
  },
  {
    path: 'cuaderno-de-campo/recursos/inventario/otros',
    loadChildren: () => import('./pages/cuaderno-de-campo/recursos/inventario/otros/otros.module').then( m => m.OtrosPageModule),
    canActivate: [ ValidarTokenGuard, ValidarModoGuard ],
    canLoad: [ ValidarTokenGuard, ValidarModoGuard ]
  },
  {
    path: 'cuaderno-de-campo/recursos/inventario/otros/crear-otros',
    loadChildren: () => import('./pages/cuaderno-de-campo/recursos/inventario/otros/crear-otros/crear-otros.module').then( m => m.CrearOtrosPageModule),
    canActivate: [ ValidarTokenGuard, ValidarModoGuard ],
    canLoad: [ ValidarTokenGuard, ValidarModoGuard ]
  },
  {
    path: 'cuaderno-de-campo/recursos/inventario/otros/modificar-otros/:id',
    loadChildren: () => import('./pages/cuaderno-de-campo/recursos/inventario/otros/modificar-otros/modificar-otros.module').then( m => m.ModificarOtrosPageModule),
    canActivate: [ ValidarTokenGuard, ValidarModoGuard ],
    canLoad: [ ValidarTokenGuard, ValidarModoGuard ]
  },
  {
    path: 'cuaderno-de-campo/recursos/inventario/tratamientos',
    loadChildren: () => import('./pages/cuaderno-de-campo/recursos/inventario/tratamientos/tratamientos.module').then( m => m.TratamientosPageModule),
    canActivate: [ ValidarTokenGuard, ValidarModoGuard ],
    canLoad: [ ValidarTokenGuard, ValidarModoGuard ]
  }, 
  {
    path: 'cuaderno-de-campo/recursos/inventario/tratamientos/medios-quimicos',
    loadChildren: () => import('./pages/cuaderno-de-campo/recursos/inventario/tratamientos/mediosquimicos/mediosquimicos.module').then( m => m.MediosquimicosPageModule),
    canActivate: [ ValidarTokenGuard, ValidarModoGuard ],
    canLoad: [ ValidarTokenGuard, ValidarModoGuard ]
  }, 
  {
    path: 'cuaderno-de-campo/recursos/inventario/tratamientos/medios-quimicos/crear-medios-quimicos',
    loadChildren: () => import('./pages/cuaderno-de-campo/recursos/inventario/tratamientos/mediosquimicos/crear-mediosquimicos/crear-mediosquimicos.module').then( m => m.CrearMediosquimicosPageModule),
    canActivate: [ ValidarTokenGuard, ValidarModoGuard ],
    canLoad: [ ValidarTokenGuard, ValidarModoGuard ]
  }, 
  {
    path: 'cuaderno-de-campo/recursos/inventario/tratamientos/medios-quimicos/modificar-medios-quimicos/:id',
    loadChildren: () => import('./pages/cuaderno-de-campo/recursos/inventario/tratamientos/mediosquimicos/modificar-mediosquimicos/modificar-mediosquimicos.module').then( m => m.ModificarMediosquimicosPageModule),
    canActivate: [ ValidarTokenGuard, ValidarModoGuard ],
    canLoad: [ ValidarTokenGuard, ValidarModoGuard ]
  }, 
  {
    path: 'cuaderno-de-campo/recursos/inventario/tratamientos/medios-alternativos',
    loadChildren: () => import('./pages/cuaderno-de-campo/recursos/inventario/tratamientos/mediosalternativos/mediosalternativos.module').then( m => m.MediosalternativosPageModule),
    canActivate: [ ValidarTokenGuard, ValidarModoGuard ],
    canLoad: [ ValidarTokenGuard, ValidarModoGuard ]
  }, 
  {
    path: 'cuaderno-de-campo/recursos/inventario/tratamientos/medios-alternativos/crear-medios-alternativos',
    loadChildren: () => import('./pages/cuaderno-de-campo/recursos/inventario/tratamientos/mediosalternativos/crear-mediosalternativos/crear-mediosalternativos.module').then( m => m.CrearMediosalternativosPageModule),
    canActivate: [ ValidarTokenGuard, ValidarModoGuard ],
    canLoad: [ ValidarTokenGuard, ValidarModoGuard ]
  }, 
  {
    path: 'cuaderno-de-campo/recursos/inventario/tratamientos/medios-alternativos/modificar-medios-alternativos/:id',
    loadChildren: () => import('./pages/cuaderno-de-campo/recursos/inventario/tratamientos/mediosalternativos/modificar-mediosalternativos/modificar-mediosalternativos.module').then( m => m.ModificarMediosalternativosPageModule),
    canActivate: [ ValidarTokenGuard, ValidarModoGuard ],
    canLoad: [ ValidarTokenGuard, ValidarModoGuard ]
  }, 
  {
    path: 'cuaderno-de-campo/recursos/inventario/tratamientos/omdf',
    loadChildren: () => import('./pages/cuaderno-de-campo/recursos/inventario/tratamientos/omdf/omdf.module').then( m => m.OmdfPageModule),
    canActivate: [ ValidarTokenGuard, ValidarModoGuard ],
    canLoad: [ ValidarTokenGuard, ValidarModoGuard ]
  }, 
  {
    path: 'cuaderno-de-campo/recursos/inventario/tratamientos/omdf/crear-omdf',
    loadChildren: () => import('./pages/cuaderno-de-campo/recursos/inventario/tratamientos/omdf/crear-omdf/crear-omdf.module').then( m => m.CrearOmdfsPageModule),
    canActivate: [ ValidarTokenGuard, ValidarModoGuard ],
    canLoad: [ ValidarTokenGuard, ValidarModoGuard ]
  }, 
  {
    path: 'cuaderno-de-campo/recursos/inventario/tratamientos/omdf/modificar-omdf/:id',
    loadChildren: () => import('./pages/cuaderno-de-campo/recursos/inventario/tratamientos/omdf/modificar-omdf/modificar-omdf.module').then( m => m.ModificarOmdfPageModule),
    canActivate: [ ValidarTokenGuard, ValidarModoGuard ],
    canLoad: [ ValidarTokenGuard, ValidarModoGuard ]
  }, 
  /*
  {
    path: 'cuaderno-de-campo/recursos/inventario/tratamientos/crear-tratamientos',
    loadChildren: () => import('./pages/cuaderno-de-campo/recursos/inventario/tratamientos/crear-tratamientos/crear-tratamientos.module').then( m => m.CrearTratamientosPageModule),
    canActivate: [ ValidarTokenGuard, ValidarModoGuard ],
    canLoad: [ ValidarTokenGuard, ValidarModoGuard ]
  },
  {
    path: 'cuaderno-de-campo/recursos/inventario/tratamientos/modificar-tratamientos/:id/:categoria',
    loadChildren: () => import('./pages/cuaderno-de-campo/recursos/inventario/tratamientos/modificar-tratamientos/modificar-tratamientos.module').then( m => m.ModificarTratamientosPageModule),
    canActivate: [ ValidarTokenGuard, ValidarModoGuard ],
    canLoad: [ ValidarTokenGuard, ValidarModoGuard ]
  },*//* 
  {
    path: 'cuaderno-de-campo/recursos/inventario/tratamientos/insecticidas',
    loadChildren: () => import('./pages/cuaderno-de-campo/recursos/inventario/tratamientos/insecticidas/insecticidas.module').then( m => m.InsecticidasPageModule),
    canActivate: [ ValidarTokenGuard, ValidarModoGuard ],
    canLoad: [ ValidarTokenGuard, ValidarModoGuard ]
  },
  {
    path: 'cuaderno-de-campo/recursos/inventario/tratamientos/crear-insecticidas',
    loadChildren: () => import('./pages/cuaderno-de-campo/recursos/inventario/tratamientos/insecticidas/crear-insecticidas/crear-insecticidas.module').then( m => m.CrearInsecticidasPageModule),
    canActivate: [ ValidarTokenGuard, ValidarModoGuard ],
    canLoad: [ ValidarTokenGuard, ValidarModoGuard ]
  },
  {
    path: 'cuaderno-de-campo/recursos/inventario/tratamientos/modificar-insecticidas',
    loadChildren: () => import('./pages/cuaderno-de-campo/recursos/inventario/tratamientos/insecticidas/modificar-insecticidas/modificar-insecticidas.module').then( m => m.ModificarInsecticidasPageModule),
    canActivate: [ ValidarTokenGuard, ValidarModoGuard ],
    canLoad: [ ValidarTokenGuard, ValidarModoGuard ]
  },
  {
    path: 'cuaderno-de-campo/recursos/inventario/tratamientos/fungicida',
    loadChildren: () => import('./pages/cuaderno-de-campo/recursos/inventario/tratamientos/fungicida/tratamientos.module').then( m => m.FungicidaPageModule),
    canActivate: [ ValidarTokenGuard, ValidarModoGuard ],
    canLoad: [ ValidarTokenGuard, ValidarModoGuard ]
  },
  {
    path: 'cuaderno-de-campo/recursos/inventario/tratamientos/crear-fungicida',
    loadChildren: () => import('./pages/cuaderno-de-campo/recursos/inventario/tratamientos/fungicida/crear-tratamientos/crear-fungicida.module').then( m => m.CrearFungicidaPageModule),
    canActivate: [ ValidarTokenGuard, ValidarModoGuard ],
    canLoad: [ ValidarTokenGuard, ValidarModoGuard ]
  },
  {
    path: 'cuaderno-de-campo/recursos/inventario/tratamientos/modificar-fungicida',
    loadChildren: () => import('./pages/cuaderno-de-campo/recursos/inventario/tratamientos/fungicida/modificar-tratamientos/modificar-fungicida.module').then( m => m.ModificarFungicidaPageModule),
    canActivate: [ ValidarTokenGuard, ValidarModoGuard ],
    canLoad: [ ValidarTokenGuard, ValidarModoGuard ]
  },
  {
    path: 'cuaderno-de-campo/recursos/inventario/tratamientos/herbicidas',
    loadChildren: () => import('./pages/cuaderno-de-campo/recursos/inventario/tratamientos/herbicidas/herbicidas.module').then( m => m.HerbicidasPageModule),
    canActivate: [ ValidarTokenGuard, ValidarModoGuard ],
    canLoad: [ ValidarTokenGuard, ValidarModoGuard ]
  },
  {
    path: 'cuaderno-de-campo/recursos/inventario/tratamientos/crear-herbicidas',
    loadChildren: () => import('./pages/cuaderno-de-campo/recursos/inventario/tratamientos/herbicidas/crear-herbicidas/crear-herbicidas.module').then( m => m.CrearHerbicidasPageModule),
    canActivate: [ ValidarTokenGuard, ValidarModoGuard ],
    canLoad: [ ValidarTokenGuard, ValidarModoGuard ]
  },
  {
    path: 'cuaderno-de-campo/recursos/inventario/tratamientos/modificar-herbicidas',
    loadChildren: () => import('./pages/cuaderno-de-campo/recursos/inventario/tratamientos/herbicidas/modificar-herbicidas/modificar-herbicidas.module').then( m => m.ModificarHerbicidasPageModule),
    canActivate: [ ValidarTokenGuard, ValidarModoGuard ],
    canLoad: [ ValidarTokenGuard, ValidarModoGuard ]
  },*/
  {
    path: 'cuaderno-de-campo/tareas',
    loadChildren: () => import('./pages/cuaderno-de-campo/tareas/tareas.module').then( m => m.TareasPageModule),
    canActivate: [ ValidarTokenGuard, ValidarModoGuard ],
    canLoad: [ ValidarTokenGuard, ValidarModoGuard ]
  },
  {
    path: 'cuaderno-de-campo/tareas/crear-tareas',
    loadChildren: () => import('./pages/cuaderno-de-campo/tareas/crear-tareas/crear-tareas.module').then( m => m.TareasCrearPageModule),
    canActivate: [ ValidarTokenGuard, ValidarModoGuard ],
    canLoad: [ ValidarTokenGuard, ValidarModoGuard ]
  },
  {
    path: 'cuaderno-de-campo/tareas/modificar-tareas',
    loadChildren: () => import('./pages/cuaderno-de-campo/tareas/editar-tareas/editar-tareas.module').then( m => m.EditarTareasPageModule),
    canActivate: [ ValidarTokenGuard, ValidarModoGuard ],
    canLoad: [ ValidarTokenGuard, ValidarModoGuard ]
  },
  {
    path: 'cuaderno-de-campo/explotaciones',
    loadChildren: () => import('./pages/cuaderno-de-campo/explotaciones/cuaderno/cuaderno.module').then( m => m.CuadernoPageModule),
    canActivate: [ ValidarTokenGuard, ValidarModoGuard ],
    canLoad: [ ValidarTokenGuard, ValidarModoGuard ]
  },
  {
    path: 'cuaderno-de-campo/explotaciones/crear',
    loadChildren: () => import('./pages/cuaderno-de-campo/explotaciones/crear/explotacion.module').then( m => m.ExplotacionPageModule),
    canActivate: [ ValidarTokenGuard, ValidarModoGuard ],
    canLoad: [ ValidarTokenGuard, ValidarModoGuard ]
  },
  {
    path: 'cuaderno-de-campo/explotaciones/modificar',
    loadChildren: () => import('./pages/cuaderno-de-campo/explotaciones/editar/editar-explotacion.module').then( m => m.EditarExplotacionPageModule),
    canActivate: [ ValidarTokenGuard, ValidarModoGuard ],
    canLoad: [ ValidarTokenGuard, ValidarModoGuard ]
  },
  {
    path: 'cuaderno-de-campo/explotaciones/ver',
    loadChildren: () => import('./pages/cuaderno-de-campo/explotaciones/ver/ver-explotacion.module').then( m => m.VerExplotacionCDCPageModule),
    canActivate: [ ValidarTokenGuard, ValidarModoGuard ],
    canLoad: [ ValidarTokenGuard, ValidarModoGuard ]
  },
  {
    path: 'cuaderno-de-campo/costes',
    loadChildren: () => import('./pages/cuaderno-de-campo/ahorro/ahorro.module').then( m => m.AhorroPageModule),
    canActivate: [ ValidarTokenGuard, ValidarModoGuard ],
    canLoad: [ ValidarTokenGuard, ValidarModoGuard ]
  },
  {
    path: 'cuaderno-de-campo/produccion',
    loadChildren: () => import('./pages/cuaderno-de-campo/produccion/produccion.module').then( m => m.ProduccionPageModule),
    canActivate: [ ValidarTokenGuard, ValidarModoGuard ],
    canLoad: [ ValidarTokenGuard, ValidarModoGuard ]
  },  
  {
    path: 'cuaderno-de-campo/produccion/crear-produccion',
    loadChildren: () => import('./pages/cuaderno-de-campo/produccion/crear-produccion/crear-produccion.module').then( m => m.CrearProduccionPageModule),
    canActivate: [ ValidarTokenGuard, ValidarModoGuard ],
    canLoad: [ ValidarTokenGuard, ValidarModoGuard ]
  },  
  {
    path: 'cuaderno-de-campo/produccion/modificar-produccion/:id',
    loadChildren: () => import('./pages/cuaderno-de-campo/produccion/modificar-produccion/modificar-produccion.module').then( m => m.ModificarProduccionPageModule),
    canActivate: [ ValidarTokenGuard, ValidarModoGuard ],
    canLoad: [ ValidarTokenGuard, ValidarModoGuard ]
  },
  {
    path: 'cuaderno-de-campo/usuarios',
    loadChildren: () => import('./pages/cuaderno-de-campo/usuarios/usuarios.module').then( m => m.UsuarioPageModule),
    canActivate: [ ValidarTokenGuard, ValidarModoGuard ],
    canLoad: [ ValidarTokenGuard, ValidarModoGuard ]
  },  
  {
    path: 'cuaderno-de-campo/usuarios/crear-usuarios',
    loadChildren: () => import('./pages/cuaderno-de-campo/usuarios/crear-usuarios/crear-usuarios.module').then( m => m.CrearUsuariosPageModule),
    canActivate: [ ValidarTokenGuard, ValidarModoGuard ],
    canLoad: [ ValidarTokenGuard, ValidarModoGuard ]
  },  
  {
    path: 'cuaderno-de-campo/usuarios/modificar-usuarios/:id',
    loadChildren: () => import('./pages/cuaderno-de-campo/usuarios/modificar-usuarios/modificar-usuarios.module').then( m => m.ModificarUsuariosPageModule),
    canActivate: [ ValidarTokenGuard, ValidarModoGuard ],
    canLoad: [ ValidarTokenGuard, ValidarModoGuard ]
  },
 // Modulo Alarmas
 {
  path: 'alarmas',
  loadChildren: () => import('./pages/alarmas/alarmas.module').then( m => m.AlarmasPageModule),
  canActivate: [ ValidarTokenGuard, ValidarModoGuard ],
  canLoad: [ ValidarTokenGuard, ValidarModoGuard ]
  },
  // Modulo Hidraulica
  {
    path: 'hidraulica',
    loadChildren: () => import('./pages/hidraulico/hidraulico.module').then( m => m.HidraulicoPageModule),
    canActivate: [ ValidarTokenGuard, ValidarModoGuard ],
    canLoad: [ ValidarTokenGuard, ValidarModoGuard ]
  },

  // Modulo Medio Ambiente
  {
    path: 'medio-ambiente',
    loadChildren: () => import('./pages/medio-ambiente/medio-ambiente.module').then( m => m.MedioAmbienteModule),
    canActivate: [ ValidarTokenGuard, ValidarModoGuard ],
    canLoad: [ ValidarTokenGuard, ValidarModoGuard ]
  },

  // Modulo GIS
  {
    path: '4sig',
    //loadChildren: () => import('./pages/sig/sig.module').then( m => m.SIGPageModule),
    loadChildren: () => import('./pages/sig2/sig.module').then( m => m.SigPageModule),
    canActivate: [ ValidarTokenGuard, ValidarModoGuard ],
    canLoad: [ ValidarTokenGuard, ValidarModoGuard ]
  },
  {
    path: 'testing',
    loadChildren: () => import('./pages/testing/testing.module').then( m => m.TestingPageModule),
    canActivate: [ ValidarTokenGuard, ValidarModoGuard ],
    canLoad: [ ValidarTokenGuard, ValidarModoGuard ]
  },

  {
    path: 'turnos-de-riego',
    loadChildren: () => import('./pages/turnos-de-riego/turnos-de-riego.module').then( m => m.TurnosDeRiegoPageModule),
    canActivate: [ ValidarTokenGuard, ValidarModoGuard ],
    canLoad: [ ValidarTokenGuard, ValidarModoGuard ]
  },
  {
    path: 'gestion',
    loadChildren: () => import('./pages/gestion/gestion.module').then( m => m.GestionPageModule),
    canActivate: [ ValidarTokenGuard, ValidarModoGuard ],
    canLoad: [ ValidarTokenGuard, ValidarModoGuard ]
  },/*
  {
    path: 'gestion/tareas/listado',
    loadChildren: () => import('./pages/gestion/tareas/listado-tareas/tareas.module').then( m => m.TareasPageModule),
    canActivate: [ ValidarTokenGuard, ValidarModoGuard ],
    canLoad: [ ValidarTokenGuard, ValidarModoGuard ]
  },
  {
    path: 'gestion/tareas/crear',
    loadChildren: () => import('./pages/gestion/tareas/crear-tareas/crear-tareas.module').then( m => m.TareasCrearPageModule),
    canActivate: [ ValidarTokenGuard, ValidarModoGuard ],
    canLoad: [ ValidarTokenGuard, ValidarModoGuard ]
  },
  {
    path: 'gestion/tareas/editar',
    loadChildren: () => import('./pages/gestion/tareas/editar-tareas/editar-tareas.module').then( m => m.EditarTareasPageModule),
    canActivate: [ ValidarTokenGuard, ValidarModoGuard ],
    canLoad: [ ValidarTokenGuard, ValidarModoGuard ]
  },
  {
    path: 'gestion/recursos/listado',
    loadChildren: () => import('./pages/gestion/recursos/listado-recursos/recursos.module').then( m => m.RecursosPageModule),
    canActivate: [ ValidarTokenGuard, ValidarModoGuard ],
    canLoad: [ ValidarTokenGuard, ValidarModoGuard ]
  },
  {
    path: 'gestion/recursos/crear',
    loadChildren: () => import('./pages/gestion/recursos/crear-recursos/recursos-crear.module').then( m => m.RecursosCrearPageModule),
    canActivate: [ ValidarTokenGuard, ValidarModoGuard ],
    canLoad: [ ValidarTokenGuard, ValidarModoGuard ]
  },
  {
    path: 'gestion/recursos/editar',
    loadChildren: () => import('./pages/gestion/recursos/editar-recursos/recursos-editar.module').then( m => m.RecursosEditarPageModule),
    canActivate: [ ValidarTokenGuard, ValidarModoGuard ],
    canLoad: [ ValidarTokenGuard, ValidarModoGuard ]
  },*/
  {
    path: 'machine-learning',
    loadChildren: () => import('./pages/machine-learning/machine-learning.module').then( m => m.MachineLearningPageModule),
    canActivate: [ ValidarTokenGuard, ValidarModoGuard ],
    canLoad: [ ValidarTokenGuard, ValidarModoGuard ]
  },
  {
    path: 'dashboards',
    loadChildren: () => import('./pages/dashboards/dashboards.module').then( m => m.DashboardsPageModule),
    canActivate: [ ValidarTokenGuard, ValidarModoGuard ],
    canLoad: [ ValidarTokenGuard, ValidarModoGuard ]
  },
  {
    path: 'ahorro',
    loadChildren: () => import('./pages/ahorro/ahorro.module').then( m => m.AhorroPageModule),
    canActivate: [ ValidarTokenGuard, ValidarModoGuard ],
    canLoad: [ ValidarTokenGuard, ValidarModoGuard ]
  },
  {
    path: 'gestion/ventas',
    loadChildren: () => import('./pages/gestion/ventas/ventas.module').then( m => m.VentasPageModule),
    canActivate: [ ValidarTokenGuard, ValidarModoGuard ],
    canLoad: [ ValidarTokenGuard, ValidarModoGuard ]
  },
  {
    path: 'tareas',
    loadChildren: () => import('./pages/tareas/tareas.module').then( m => m.TareasPageModule),
    canActivate: [ ValidarTokenGuard, ValidarModoGuard ],
    canLoad: [ ValidarTokenGuard, ValidarModoGuard ]
  },
  {
    path: 'gestion/stock',
    loadChildren: () => import('./pages/gestion/stock/stock.module').then( m => m.StockPageModule),
    canActivate: [ ValidarTokenGuard, ValidarModoGuard ],
    canLoad: [ ValidarTokenGuard, ValidarModoGuard ]
  },
  // Rutas especiales para el ambiente de Produccion 4.1
  // *** Sin el token correcto a las siguentes rutas no podrá accederse ***
  { 
    path: 'cexplotacion/:data',
    redirectTo: 'redireccionador/cexplotacion/:data'
  },
  { 
    path: 'map/:data',
    redirectTo: 'redireccionador/map/:data'
  },
  { 
    path: 'gestion-tareas/:data',
    redirectTo: 'redireccionador/gestion-tareas/:data'
  },
  { 
    path: 'gestion-recursos/:data',
    redirectTo: 'redireccionador/gestion-recursos/:data'
  },
  { 
    path: 'energia-informe-mensual/:data',
    redirectTo: 'redireccionador/energia-informe-mensual/:data'
  },
  { 
    path: 'energia-informe-semanal/:data',
    redirectTo: 'redireccionador/energia-informe-semanal/:data'
  },
  { 
    path: 'energia-informe-cierre-mensual/:data',
    redirectTo: '/redireccionador/energia-informe-cierre-mensual/:data'
  },
  { 
    path: 'ajuste-bombas/:data',
    redirectTo: 'redireccionador/ajuste-bombas/:data'
  },
  {
    path: 'gestion-dispositivos/:data',
    redirectTo: 'redireccionador/gestion-dispositivos/:data'
  },
  { 
    path: 'indices/:data',
    redirectTo: 'redireccionador/indices/:data'
  },
  // Esta ruta es publica y permite el acceso directo a las diferentes rutas (Distribuidor)
  {
    path: 'sanidad/:data',
    redirectTo: 'redireccionador/sanidad/:data'
  },
  // Esta ruta es publica y permite el acceso directo a las diferentes rutas (Distribuidor)
  {
    path: 'redireccionador/:pages/:data',
    loadChildren: () => import('./pages/redireccionador/redireccionador.module').then( m => m.RedireccionadorPageModule)
  },
  {
    path: 'sensores',
    loadChildren: () => import('./pages/sensores-2/sensores.module').then( m => m.SensoresPageModule),
    canActivate: [ ValidarTokenGuard, ValidarModoGuard ],
    canLoad: [ ValidarTokenGuard, ValidarModoGuard ]
  },
  {
    path: 'sensores-1',
    loadChildren: () => import('./pages/sensores/sensores.module').then( m => m.SensoresPageModule),
    canActivate: [ ValidarTokenGuard, ValidarModoGuard ],
    canLoad: [ ValidarTokenGuard, ValidarModoGuard ]
  },
  {
    path: 'bienestar-vegetal',
    loadChildren: () => import('./pages/bienestar-vegetal/bienestar-vegetal.module').then( m => m.BienestarVegetalPageModule),
    canActivate: [ ValidarTokenGuard, ValidarModoGuard ],
    canLoad: [ ValidarTokenGuard, ValidarModoGuard ]
  },
  {
    path: 'sanidad-vegetal',
    loadChildren: () => import('./pages/sanidad-vegetal/sanidad-vegetal.module').then( m => m.SanidadVegetalPageModule)
  },
  {
    path: 'energia',
    loadChildren: () => import('./pages/energia/energia.module').then( m => m.EnergiaPageModule),
    canActivate: [ ValidarTokenGuard, ValidarModoGuard ],
    canLoad: [ ValidarTokenGuard, ValidarModoGuard ]
  },
  { 
    path: '**',  
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule),
    canActivate: [ ValidarTokenGuard, ValidarModoGuard ],
    canLoad: [ ValidarTokenGuard, ValidarModoGuard ]
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'corrected' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
