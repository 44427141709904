import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { UserLogin } from 'src/app/model/user-login.model';
import { environment } from 'src/environments/environment';
import { ResponseAuth,ResponseAuthApiV1 } from '../models/auth';
import { UtilsService } from 'src/app/services/utils.service';

enum Roles {
  ADMIN = 1,
  TECNICO = 2,
  COMUNERO = 3
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private url_pro: string;
  private id: number;
  public url: string;
  public url_V3: string;
  public url_api_v2: string
  

  public userDatasubject$: BehaviorSubject<UserLogin> = new BehaviorSubject<UserLogin>(null);
  public userData$: Observable<UserLogin> = this.userDatasubject$.asObservable();

  get uid() {
    return this.id;
  }

  constructor(
    private _http: HttpClient,
    public us: UtilsService,
  ) {
    this.url_pro = environment.url_pro;
    //this.url_api_v2 = environment.url_api_v2;
    this.url = environment.url;
    this.url_V3 = environment.url_v3;        
  }

  

  /**
   * Metodo para registrar
   * @param user User del usuario (Debe estar registrado)
   * @param pass Contraseña
   * @returns {Observable<boolean>}
   */
  login(username: string, password: string) {
    const data = { username, password }
    const body = JSON.stringify(data);
    //return this._http.post(this.url + '/login/user', body);
    return this._http.post<ResponseAuthApiV1>(this.url + 'login/auth', body).pipe(
      tap(resp => {
        const id = resp.uid;
        const num_socio = resp.num_socio/*?resp.num_socio:1*/;
        const rol = resp.rol;
        const cant_comuneros = resp.cant_comuneros;
        const msg_entrada = resp.msg;
        const id_comunidad_regantes = 0;// resp.data.user_info['4piot_info'].id_comunidad;
        const token = resp.token;
        this.id = id;
        sessionStorage.setItem("cant_comuneros", JSON.stringify(cant_comuneros));
        sessionStorage.setItem("msg_entrada", JSON.stringify(msg_entrada));
        this.setUserLogin({ id, num_socio, rol, id_comunidad_regantes, token, entrada: 'Angular' });
        this.setToken(token);        
      }),
      map(resp => true),
      catchError(err => of(err))
    );
  }
  /**
   * Metodo para login
   * @param user User del usuario (Debe estar registrado)
   * @param pass Contraseña
   * @returns {Observable<boolean>}
   */
  /*login(user: string, pass: string): Observable<boolean> {
    const data = { user, pass }
    const body = JSON.stringify(data);
    return this._http.post<ResponseAuth>(`${this.url_pro}auth/login`, body)
      .pipe(
        tap(resp => {
          const id = resp.data.user_info['4piot_info'].idUser;
          const num_socio = resp.data.user_info['4piot_info'].n_socio;
          const rol = resp.data.user_info['4piot_info'].rol;
          const id_comunidad_regantes = resp.data.user_info['4piot_info'].id_comunidad;
          const token = resp.data.session_id.token;
          this.id = id;
          this.setUserLogin({ id, num_socio, rol, id_comunidad_regantes, token, entrada: 'Angular' });
          this.setToken(token);
        }),
        map(resp => true),
        catchError(err => of(err))
      );
  }*/

  validarToken(): Observable<boolean> {
    return this._http.get<any>(this.url + 'login/renew')
      .pipe(
        tap(resp => {
          const token = resp.token;
          this.setToken(token);
        }),
        map(resp => true),
        catchError(err => of(false))
      );
  }
  /*validarToken(): Observable<boolean> {
    return this._http.get<any>(`${this.url_pro}auth/renew`)
      .pipe(
        tap(resp => {}),
        map(resp => true),
        catchError(err => of(false))
      );
  }*/
/*
  logout(): Observable<boolean> {
    return this._http.post<any>(`${this.url_pro}auth/logout`, {})
      .pipe(
        tap(resp => {
          sessionStorage.clear();
          localStorage.clear();
        }),
        map(resp => true),
        catchError(err => of(false))
      );
  }*/
  logout(): Observable<boolean> {
    return this._http.post<any>(this.url + 'logout/auth', {})
      .pipe(
        tap(resp => {
          sessionStorage.clear();
          localStorage.clear();
        }),
        map(resp => true),
        catchError(err => of(false))
      );
  }

  /**
   * Metodo para guardar token en el localStorage
   * @param token Token
   */
  setToken(token: string) {
    localStorage.setItem('4plus-token', token);
  }

  /**
   * Metodo para obtener el token del localStorage
   * @returns {string}
   */
  getToken(): string {
    return localStorage.getItem('4plus-token') || '';
  }

  /**
  * Metodo que devuelve los datos del usuario autenticado. 
  * @returns 
  */
  getUserLogin(): UserLogin | undefined {
    const aux = sessionStorage.getItem("user_login");
    if (aux) {
      return JSON.parse(aux);
    } else {
      return undefined;
    }
  }

  /**
  * Metodo que establece los datos del usuario autenticado.
  * @param userLogin 
  */
  setUserLogin(userLogin: UserLogin) {
    this.userDatasubject$.next(userLogin);
    sessionStorage.setItem("user_login", JSON.stringify(userLogin));
  }

  /**
  * Metodo que devuelve si el usuario autenticado tiene Rol Administrador. 
  * @returns boolean 
  */
   isAdminRole(): boolean | undefined {
    const data = this.getUserLogin();
    if (data) {
      return data.rol == Roles.ADMIN;
    } else {
      return undefined;
    }
  }

  /**
  * Metodo que devuelve si el usuario autenticado tiene Rol Comunero. 
  * @returns boolean 
  */
  isComuneroRole(): boolean | undefined {
    const data = this.getUserLogin();
    if (data) {
      return data.rol == Roles.COMUNERO;
    } else {
      return undefined;
    }
  }

  /**
  * Metodo que devuelve si el usuario autenticado tiene Rol Técnico. 
  * @returns boolean 
  */
   isTecnicoRole(): boolean | undefined {
    const data = this.getUserLogin();
    if (data) {
      return data.rol == Roles.TECNICO;
    } else {
      return undefined;
    }
  }

  /**
  * obtiene si el usuario autenticado firmo el acuerdo de protección de datos 
  */
  getDataProtectionState() {
    //let { num_socio } = this.us.getUserLogin();
    return this._http.get<any>(this.url + `global/get-data-protection/`);
  }

  /**
  * Firma la protección de datos
  */
  setDataProtectionState() {
    //let { num_socio } = this.us.getUserLogin();
    return this._http.get<any>(this.url + `global/set-data-protection/`);
  }

  /** Enviar email a soporte apiV1*/
  /*senEmail(message,subject,to) {
    return this._http.get(this.url_V3 + `utils/email?message=${message}&subject=${subject}&to=${to}`);
  }*/
  /** Enviar email a soporte apiV1*/ 
  senEmail(message,subject,to) {
    const data = { message,subject,to}
    const body = JSON.stringify(data);
    return this._http.post(this.url + `email`, body);
  }
}
