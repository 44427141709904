import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage-angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MomentModule } from 'angular2-moment';
import { MenuComponent } from './components/menu/menu.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ReactiveFormsModule } from '@angular/forms';

import { NgxChartsModule }from '@swimlane/ngx-charts';
import { HighchartsChartModule } from 'highcharts-angular';
import { MatButtonModule } from '@angular/material/button';

import { ThemeScrollModule } from './directives/theme-scrol.directive';
import { InterceptorService } from './interceptors/interceptor.service';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { UrlService } from './services/url.service';

import { ModalEmailSoporteComponent } from './components/modal-email-soporte/modal-email-soporte.component';

//import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
//const config: SocketIoConfig = { url: 'http://localhost:4444', options: {} };

@NgModule({
    declarations: [AppComponent, MenuComponent, ModalEmailSoporteComponent],
    imports: [
        BrowserModule,
        IonicModule.forRoot(),
        IonicStorageModule.forRoot(),
        AppRoutingModule,
        LeafletModule,
        HttpClientModule,
        MomentModule,
        BrowserAnimationsModule,
        MatTooltipModule,
        MatButtonModule,
        ReactiveFormsModule,
        NgxChartsModule,
        HighchartsChartModule,
        // Diretives
        ThemeScrollModule,
        // Module Snackbar
        MatSnackBarModule
    ],
    providers: [
        StatusBar,
        SplashScreen,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: InterceptorService,
            multi: true
        },
        UrlService
    ],
    bootstrap: [AppComponent],
    exports: [BrowserAnimationsModule, ReactiveFormsModule]
})
export class AppModule {}
