import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { fromEvent } from 'rxjs';
import { UtilsService } from 'src/app/services/utils.service';
import { AuthService } from '../../pages/login/services/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'modal-email-soporte',
  templateUrl: './modal-email-soporte.component.html',
  styleUrls: ['./modal-email-soporte.component.scss'],
})
export class ModalEmailSoporteComponent implements OnInit {

  //@Input() public cuaderno : Cuaderno;

  /** Controller Popover */
  customPopoverOptions: any = {
    showBackdrop: false
  };

  showDescrption: boolean = false;
  textareaValue: string = '';
  constructor(
    private router : Router,
    public us : UtilsService,
    private _snackBar: MatSnackBar,
    //public cs : CuadernoService,
    private modalController: ModalController,
    private authService: AuthService
  ) { }

  ngOnInit() {}

  onCloseModal() {
    this.modalController.dismiss({
      respuesta: '000',
      data: null
    });
  }

  changeMessage($event?: any) {      
    this.textareaValue = $event.target.value;
  }

  onAcept(){
    console.log(this.textareaValue);
    this.modalController.dismiss({ respuesta: '00', data: true });
    this.authService.senEmail(this.textareaValue,this.authService.getUserLogin()/*.num_socio*/,'soporte@4piot.es').subscribe( (result: any) => {
			console.log(result.status)
      if(result.status=='ok'){
        this._snackBar.open('Se a enviado un correo a soporte', 'Info.', {
          duration: 5000
        });
      }
			

		},error => { },
		() => {			
			
		});
  }
  

}
