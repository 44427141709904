import { NgZone } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MenuController, PopoverController } from '@ionic/angular';
import { MenuOpcionLateral } from 'src/app/model';
import { DataService } from 'src/app/services/data/data.service';
import { trigger, state, style, animate, transition } from '@angular/animations';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  animations: [
    trigger(
      'inOutAnimation', 
      [
        transition(
          ':enter', 
          [
            style({ opacity: 0 }),
            animate('1s ease-out', 
                    style({ opacity: 1 }))
          ]
        ),
        transition(
          ':leave', 
          [
            style({ opacity: 1 }),
            animate('0.2s ease-in', 
                    style({ opacity: 0 }))
          ]
        )
      ]
    )
  ]
})
export class MenuComponent implements OnInit {

    /** Observable que recibe y actualiza data en el Front */
    public opciones: MenuOpcionLateral[];

  constructor(
    private _dataServices: DataService,
    private menu : MenuController,
    private zone: NgZone,
    private popoverCtrl: PopoverController
  ) { }

  ngOnInit() {
    this._dataServices.getMenuLateralOpts().subscribe((opciones => {
      this.opciones = opciones;
    }));
  }

  closeMenu() {
    this.menu.close("menuPrincipal");
  }

  onFocus(btn:string) {
    this.opciones.map((opcion, index) => {
      if (btn == opcion.name) {
        this.zone.run(() => {
          var aux = opcion.normal;
          opcion.normal = opcion.hover;
          opcion.hover = aux;
          opcion.active = !opcion.active;
        })
      }
    });
  }

  onLeave(btn:string) {
    this.opciones.map((opcion, index) => {
      if (btn == opcion.name) {
        var aux = opcion.normal;
        opcion.normal = opcion.hover;
        opcion.hover = aux;
        opcion.active = !opcion.active;
      }
    });
  }

}
