import { Directive, ElementRef, NgModule } from '@angular/core';

const stylesheet = `
::-webkit-scrollbar {
  background: rgba(0, 0, 0, 0.616);
  width: 10px;
  cursor: pointer;
  border-radius: 4px;
}
::-webkit-scrollbar-thumb {
  background:  #ffffff9d;
  border-radius: 4px;
}
::-webkit-scrollbar-thumb:hover {
  background: #b3b3b3;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}
::-webkit-scrollbar-thumb:active {
  background-color: #cecece;
}
`;

@Directive({
  selector: '[appThemeScroll]'
})
export class ThemeScrollDirective {

  constructor(el: ElementRef) {

    const styleElmt = el.nativeElement.shadowRoot.querySelector('style');

    if (styleElmt) {
      styleElmt.append(stylesheet);
    } else {
      const barStyle = document.createElement('style');
      barStyle.append(stylesheet);
      el.nativeElement.shadowRoot.appendChild(barStyle);
    }

  }
}

@NgModule({
  declarations: [ ThemeScrollDirective ],
  exports: [ ThemeScrollDirective ]
})
export class ThemeScrollModule {}
