export const environment = {
  production: true,
  url:'https://cuaderno.4piot.es/api/v1/',
  url_pro:'https://cuaderno.4piot.es/api/v2/',
  url_v3:'https://cuaderno.4piot.es/api/v3/',
  url_sockeIO:'https://cuaderno.4piot.es/api/socket',
  url_schedule:"https://cuaderno.4piot.es/api/schedule/",
  name: 'CUADERNO2',
  api_key_google_maps : 'AIzaSyA3YRaeKU97vF9pOnky67Z_NVmFknycZDo',
  PHP: {
    login: 'https://cuaderno.4piot.es/das/panel/login.php',
    home: 'https://cuaderno.4piot.es/das/panel/index.php',
    iframe: 'https://cuaderno.4piot.es/das/panel'
  },
  odoo:'http://cuaderno.4piot.es/erp/web/login?disable_footer=True'/*,*/
  //odoo:'http://csod.4piot.es/web/login?disable_footer=True'
};
